import { render, staticRenderFns } from "./personInfo.vue?vue&type=template&id=759b4ad6&scoped=true&"
import script from "./personInfo.vue?vue&type=script&lang=js&"
export * from "./personInfo.vue?vue&type=script&lang=js&"
import style0 from "./personInfo.vue?vue&type=style&index=0&id=759b4ad6&lang=scss&scoped=true&"
import style1 from "./personInfo.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "759b4ad6",
  null
  
)

export default component.exports