/**
 * author Liu Yin
 * date 2020/4/2
 * Description:
 */
export default {
  data() {
    return {
      salaryList: [
        {
          name: '小于5K',
          maxSalary: 5000
        },
        {
          name: '5K-8K',
          minSalary: 5000,
          maxSalary: 8000
        },
        {
          name: '8K-10K',
          minSalary: 8000,
          maxSalary: 10000
        },
        {
          name: '10K-15K',
          minSalary: 10000,
          maxSalary: 15000
        },
        {
          name: '15K-20K',
          minSalary: 15000,
          maxSalary: 20000
        },
        {
          name: '20K-30K',
          minSalary: 20000,
          maxSalary: 30000
        },
        {
          name: '30K以上',
          minSalary: 30000
        }
      ],
      timeList: [
        {
          name: '不限发布时间'
        },
        {
          name: '最近3天'
        },
        {
          name: '最近一周'
        },
        {
          name: '最近一月'
        },
        {
          name: '最近一年'
        }
      ],
      experienceList: [
        {
          name: '不限工作经验'
        },
        {
          name: '无工作经验',
          maxWorkDate: '1820-04-08'
        },
        {
          name: '1年以下',
          minWorkDate: this.getDayByDiffYear(1),
          maxWorkDate: this.currentDay()
        },
        {
          name: '1-3年',
          minWorkDate: this.getDayByDiffYear(3),
          maxWorkDate: this.getDayByDiffYear(1)
        },
        {
          name: '3-5年',
          minWorkDate: this.getDayByDiffYear(5),
          maxWorkDate: this.getDayByDiffYear(3)
        },
        {
          name: '5-8年',
          minWorkDate: this.getDayByDiffYear(8),
          maxWorkDate: this.getDayByDiffYear(5)
        },
        {
          name: '8年以上',
          minWorkDate: this.getDayByDiffYear(8)
        }
      ],
      workNatureList: [],
      sourceList: [
        {
          name: '中软'
        },
        {
          name: '园区'
        },
        {
          name: '企业'
        }
      ]
    }
  },
  methods: {
    // Array转化成Parent->children
    converseData(originList) {
      if (!originList) return []
      const list = JSON.parse(JSON.stringify(originList))
      const result = []
      const map = {}
      list.forEach(item => {
        item.label = item.dictValue
        item.value = item.dictCode
        map[item.dictId] = item
      })
      list.forEach(item => {
        const parent = map[item.parentId]
        if (parent) {
          (parent.children || (parent.children = [])).push(item)
        } else {
          result.push(item)
        }
      })
      return result
    },
    currentDay() {
      return this.$moment(new Date()).format('YYYY-MM-DD')
    },
    getDayByDiffYear(diffYear) {
      if (!diffYear) return null
      if (String(diffYear).indexOf('.') > 0) {
        diffYear = Math.ceil(diffYear)
      }
      const date = new Date()
      var strYear = date.getFullYear() - diffYear
      var strDay = date.getDate()
      var strMonth = date.getMonth() + 1
      if (strMonth < 10) {
        strMonth = '0' + strMonth
      }
      if (strDay < 10) {
        strDay = '0' + strDay
      }
      const dateStr = strYear + '-' + strMonth + '-' + strDay
      return dateStr
    },

    // 获取n天前的日期
    getCreateTime(time) {
      let diffDays
      switch (time) {
        case '不限发布时间':
          return null
        case '最近3天':
          diffDays = 3
          break
        case '最近一周':
          diffDays = 7
          break
        case '最近一月':
          diffDays = 30
          break
        case '最近一年':
          diffDays = 365
          break
      }
      const curTime = new Date().getTime()
      let startDate = curTime - (diffDays * 3600 * 24 * 1000)
      startDate = this.$moment(new Date(startDate)).format('YYYY-MM-DD')
      return startDate
    }
  }
}
