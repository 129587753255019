<!--
   User: Liu Yin
   Date: 2020/7/30
   Description:企业详情
 -->
<template>
  <article>
    <section v-if="!showFileDialog">
      <section class="company-constraint">
        <Nav class="breadcrumb" :source-num-list="sourceNumList" @close="close"></Nav>
        <article class="post-info">
          <!-- <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
            <el-breadcrumb-item>
              <span v-if="sourceNum===1" class="curpoin" @click="close(sourceNum)">招聘推荐</span>
              <span v-if="sourceNum===4" class="curpoin" @click="close(sourceNum)">企业详情</span>
              <span v-if="sourceNum===3" class="curpoin" @click="close(sourceNum)">岗位列表</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item>岗位详情</el-breadcrumb-item>
          </el-breadcrumb> -->

          <section class="info-left">
            <div style="margin-left: 20px">
              <div style="display: flex;align-items: center">
                <h1 class="name">{{ detailData.jobname }}</h1>
                <h2 class="salary">{{ detailData.salaryPackage }}</h2>
              </div>
              <p>
                {{ detailData.workAddress }}
                <em v-if="detailData.workAddress" class="dolt"></em>
                {{ detailData.workYear }}
                <em v-if="detailData.workYear" class="dolt"></em>
                {{ formattingField(educationList, detailData.education, 'dictCode', 'dictValue') }}
              </p>
              <ul class="post-label">
                <li v-for="(labelItem,labelIndex) in techniques" :key="labelIndex">
                  {{ labelItem.skill }}
                </li>
              </ul>
            </div>
          </section>
          <section class="info-right">
            <el-button class="deliver-btn" type="warning" size="medium" @click="deliverResume">投递简历</el-button>
          </section>
        </article>
      </section>
      <section class="company-info-container">
        <el-tabs type="border-card" class="post-description">
          <el-tab-pane label="职位描述">
            <p class="text">{{ detailData.jobdeions }}</p>
            <h5 style="margin-bottom: 8px">
              职位要求
            </h5>
            <p class="text">{{ detailData.qualification }}</p>
          </el-tab-pane>
        </el-tabs>
        <el-tabs type="border-card" class="company-info">
          <el-tab-pane label="公司基本信息">
            <div class="company-name">
              <img class="logo" :src="enterInfo.enterLogo">
              <span>{{ enterInfo.enterName }}</span>
            </div>
            <ul>
              <li v-if="enterInfo.enterType" class="attribute">
                <i class="el-icon-position"></i>
                {{ enterInfo.enterType }}
              </li>
              <li v-if="enterInfo.enterScale" class="attribute">
                <i class="el-icon-user"></i>
                {{ enterInfo.enterScale }}
              </li>
              <li class="attribute">
                <i v-if="enterInfo.enterField" class="el-icon-bangzhu"></i>
                {{ enterInfo.enterField }}
              </li>
              <li class="attribute">
                <i v-if="enterInfo.enterWebsite" class="el-icon-office-building"></i>
                {{ enterInfo.enterWebsite }}
              </li>
            </ul>
            <h3 class="company-introduce">公司介绍</h3>
            <p class="text" style="margin-top: 10px">{{ enterInfo.enterDetails }}</p>
          </el-tab-pane>
        </el-tabs>
      </section>
    </section>

    <personInfo v-else :id="id" :dict-obj="dictObj" @close="showFileDialog=false"></personInfo>
  </article>
</template>

<script>
import personInfo from './personInfo'
import pageMixin from '../../../../../utils/mixins/pageMixin'
import Nav from './nav.vue'

export default {
  name: 'PostDetail',
  components: {
    personInfo,
    Nav
  },
  mixins: [pageMixin],
  props: {
    id: {
      type: Number,
      default: 0
    },
    sourceNum: {
      type: Number,
      default: 1
    },
    sourceNumList: {
      type: Array,
      default() {
        return [1, 2]
      }
    }
  },
  data() {
    return {
      detailData: {},
      techniques: [],
      enterInfo: {},
      showFileDialog: false,
      educationList: [],
      dictObj: {}
    }
  },
  mounted() {
    document.body.scrollTop = 0
    this.getDetailData()
    this.getDictObj()
  },
  methods: {
    close(num) {
      this.$emit('close', num)
    },
    deliverResume() {
      this.showFileDialog = true
    },
    getDictObj() {
      this.$get(`${this.urls.dicValues}`, { types: 'area,enter_industry,job_status,work_nature,education,college_type' }).then(result => {
        if (result.code === 'SUCCESS') {
          const data = result.data
          this.dictObj = result.data
          this.educationList = data.education
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    },
    getDetailData() {
      this.$get(this.urls.stationDetail, null, [this.id]).then(result => {
        if (result.code === 'SUCCESS') {
          const data = result.data
          const portrayal = data.portrayal || {}
          this.techniques = portrayal.techniques
          this.detailData = data
          this.getEnterInfo(data.entcode)
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
      })
    },
    getEnterInfo(entCode) {
      this.$get(this.urls.enterDetail, null, [entCode]).then(result => {
        if (result.code === 'SUCCESS') {
          const data = result.data
          this.enterInfo = data
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.company-constraint {
  height: 180px;
  position: relative;
  .post-info {
    // position: relative;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .post-label {
    margin-top: 20px;
  }
  .salary {
    line-height: 48px;
  }
  .deliver-btn {
    width: 150px;
  }
  .edit-resume {
    margin-top: 20px;
    display: flex;
    p {
      margin-left: 25px;
    }
  }
}
.company-info-container {
  display: flex;
  min-height: 500px;
  width: 1200px;
  margin: 0px auto;
  .post-description {
    width: 650px;
    flex-shrink: 0;
  }
  .company-info {
    margin-left: 15px;
  }
}
.name {
  font-size: 30px;
}
.salary {
  font-size: 27px;
}
.company-constraint {
  background-color: rgb(68, 76, 95);
  .post-info {
    // height: 100%;
    .info-left {
      .name {
        padding: 0 0 3px 0;
        color: white;
        font-weight: 400;
      }
      .salary {
        padding: 0 0 3px 0;
        margin-left: 20px;
        color: #fa6a43;
        font-weight: 400;
      }
      p {
        line-height: 20px;
        padding: 0;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 13px;
        font-size: 14px;
        .dolt {
          display: inline-block;
          vertical-align: middle;
          width: 2px;
          height: 2px;
          margin: 0 7px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.7);
        }
      }
    }
    .info-right {
      text-align: center;
      margin-right: 20px;
      .edit-resume {
        p {
          color: #f5a724;
          cursor: pointer;
          font-size: 15px;
          i {
            margin-right: 2px;
          }
        }
        p:hover {
          color: #ba8c2d;
        }
      }
    }
  }
}
.text {
  padding: 0 5px 20px 0;
  color: #53596a;
  line-height: 36px;
  font-size: 14px;
  white-space: pre-wrap;
}
.block-title {
  font-weight: 400;
  color: #53596a;
  margin-top: 20px;
  font-size: 20px;
}
.address {
  margin-top: 15px;
  font-size: 14px;
}
.post-label {
  display: flex;
  align-items: center;
  li {
    padding: 3px 5px;
    border: #ededed solid 1px;
    border-radius: 3px;
    font-size: 12px;
    margin-right: 15px;
    font-weight: 300;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }
}
.company-info {
  flex-grow: 1;
  .company-name {
    display: flex;
    align-items: center;
    .logo {
      width: 60px;
      height: 60px;
      margin-right: 18px;
      border-radius: 10px;
    }
  }
  .attribute {
    color: #858585;
    font-size: 14px;
    line-height: 35px;
    i {
      font-size: 20px;
      margin-right: 20px;
      vertical-align: middle;
    }
  }
  .company-introduce {
    font-size: 20px;
    font-weight: 400;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .company-introduce::after {
    content: "";
    width: 80px;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: -13px;
    background: linear-gradient(90deg, #f5cd4f, #f4a522);
    border-radius: 2px;
  }
}
.breadcrumb {
  // position: absolute;
  // top: 20px;
  // left: 0px;
  padding: 20px 0 20px 40px;
  margin: 0 auto;
  // border-bottom: 1px solid #ededed;
  max-width: 1280px;
}
::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover {
  font-weight: 400;
  color: #fff;
  cursor: text;
}
::v-deep .el-breadcrumb__inner {
  color: #fff;
}
.curpoin {
  cursor: pointer;
}
</style>
