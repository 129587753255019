<!--
   User: Liu Yin
   Date: 2020/4/3
   Description:新增编辑简历
 -->
<template>
  <article class="resume">
    <Nav class="breadcrumb" :source-num-list="sourceNumList" @close="close"></Nav>
    <section v-if="showMatch" class="suspend-view" :class="{'pack-up':hideMatch}">
      <transition name="fade">
        <div v-if="hideMatch" style="width: 100px;height: 35px;background: #3576e4;color: white;cursor:pointer;top: 0;position: absolute;right: 0;font-size: 14px;display: flex;align-items: center;justify-content: center;border-top-left-radius: 5px;border-bottom-left-radius: 5px" @click="setVisibility(true)">
          {{ text }}
        </div>
      </transition>
    </section>
    <article class="resume-template">
      <section style="position: fixed;z-index: 2;margin-left: -60px;margin-top: 10px">
        <!-- <return-key @close="handleBack()"></return-key> -->
        <div v-if="showInStorage" class="in-storage-parent" @click="entryStorage()">
          <svg-icon icon-class="in-storage" class-name="in-storage"></svg-icon>
          <span class="storage-text">入库</span>
        </div>
      </section>

      <person-info :resume="resume" :dict-obj="dictObj" :person-id="personId" :organize-list="organizeList" :origin-organize-list="originOrganizeList" :handle-enable="handleEnable" @exit="handleBack" @saveSuccess="addPerson"></person-info>
      <el-tabs v-if="!handleEnable" v-model="activeName" class="resume-tabs" @tab-click="handleTabClick">
        <el-tab-pane label="简历详情" name="introduce"></el-tab-pane>
        <!-- <el-tab-pane label="测评结果" name="evaluate">
          <skill-evaluate :resume="resume" :evaluate-id="evaluateId" />
        </el-tab-pane>
        <el-tab-pane v-if="resumeType===4" label="面试评价" name="interview">
          <assess-record :person-id="personId"></assess-record>
        </el-tab-pane>
        <el-tab-pane label="简历评定">
          <assess-info handle-enable :resume="resume" name="resumeEvaluate"></assess-info>
        </el-tab-pane>
        <el-tab-pane label="技能云图" name="skillCloud">
          <world-cloud :word-list="wordList"></world-cloud>
        </el-tab-pane> -->
      </el-tabs>
      <div v-show="activeName==='introduce'">
        <section v-if="personId">
          <hr v-if="handleEnable">
          <experience-info :resume="resume" :dict-obj="dictObj" :person-id="personId" :handle-enable="handleEnable" @saveSuccess="getResume"></experience-info>
          <hr>
          <project-info :resume="resume" :person-id="personId" :handle-enable="handleEnable" @saveSuccess="getResume"></project-info>
          <hr>
          <education-info :resume="resume" :person-id="personId" :handle-enable="handleEnable" :dict-obj="dictObj" @saveSuccess="getResume"></education-info>
          <hr>
          <prize-info :resume="resume" :person-id="personId" :handle-enable="handleEnable" @saveSuccess="getResume"></prize-info>
          <hr>
          <certificate-info :resume="resume" :person-id="personId" :handle-enable="handleEnable" @saveSuccess="getResume"></certificate-info>
          <hr>

          <skill-info :resume="resume" :person-id="personId" :handle-enable="handleEnable" @saveSuccess="getResume"></skill-info>
          <hr>
          <evaluate-info :resume="resume" :person-id="personId" :handle-enable="handleEnable"></evaluate-info>
          <!-- <hr>
          <file-upload-info :resume="resume" :person-id="personId" :handle-enable="handleEnable"></file-upload-info> -->
        </section>
      </div>
    </article>
  </article>
</template>

<script>
import personInfo from './components/resume/personInfo'
import experienceInfo from './components/resume/experienceInfo'
import projectInfo from './components/resume/projectInfo'
import educationInfo from './components/resume/educationInfo'
import prizeInfo from './components/resume/prizeInfo'
import certificateInfo from './components/resume/certificateInfo'
import evaluateInfo from './components/resume/evaluateInfo'
import skillInfo from './components/resume/skillInfo'
import Nav from './components/nav.vue'
export default {
  components: {
    personInfo,
    experienceInfo,
    projectInfo,
    educationInfo,
    prizeInfo,
    certificateInfo,
    evaluateInfo,
    skillInfo,
    Nav
  },
  props: {
    sourceNumList: {
      type: Array,
      default() {
        return [1, 7]
      }
    },
    originPersonId: {
      type: [Number, Object],
      default() {
        return null
      }
    },
    organizeList: {
      type: Array,
      default() {
        return []
      }
    },
    originOrganizeList: {
      type: Array,
      default() {
        return []
      }
    },
    // 是否可编辑
    handleEnable: {
      type: Boolean,
      default: false
    },
    // 是否展示匹配框
    showMatch: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default() {
        return null
      }
    },
    showInStorage: {
      type: Boolean,
      default: false
    },
    evaluateId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      resume: {},
      dictObj: {},
      dict: {},
      activeName: 'introduce',
      personId: this.originPersonId,
      showAddPost: false,
      showInterView: false,
      // 技能云图
      wordList: [],
      hideMatch: false,
      text: '<<岗位匹配'
    }
  },
  computed: {
    // 查看简历的不同状态  1 投递管理或岗位管理  2收藏管理  3简历管理  4面试管理
    resumeType() {
      return this.$store.getters.resumeType
    }
  },
  mounted() {
    if (this.personId) {
      this.getResume()
    }
    this.getDictObj()
  },
  beforeDestroy() {
    this.$store.dispatch('app/setResumeType', 0)
  },
  methods: {
    close(num) {
      this.$emit('close', num)
    },
    setVisibility() {
      this.hideMatch = !this.hideMatch
    },
    handleTabClick(val) {

    },

    // 展示邀请面试
    handleShowInterview() {
      this.showInterView = true
    },

    noticeUpdate() {
      this.$refs.postMatch.viewGoneBtn()
      this.$emit('update')
    },
    addPerson(id) {
      this.personId = id
      this.$emit('update')
      this.getResume()
    },
    // 获取字典数据
    getDictObj() {
      this.$get(`${this.urls.dicValues}`, { types: 'enter_industry,area,work_nature,education,job_status,college_type' }).then(result => {
        if (result.code === 'SUCCESS') {
          this.dictObj = result.data
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    },
    handleBack() {
      this.$emit('handleBack')
    },
    getResume() {
      this.toggleLoading(true)
      this.$get(this.urls.personInfo, {}, [this.personId]).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          const data = result.data
          const now = new Date().getTime()
          if (data.birthdate) {
            const ageMilli = now - new Date(data.birthdate).getTime()
            const age = Math.floor(ageMilli / (365 * 24 * 3600 * 1000)) + 1
            data.age = age
          }
          if (new Date(data.workdate).getTime() !== 0) {
            const workMilli = now - new Date(data.workdate).getTime()
            const workExperience = Math.floor(workMilli / (365 * 24 * 3600 * 1000)) + 1
            data.workExperience = workExperience
          } else if (new Date(data.educations[0].endTime).getTime()) {
            const workMilli = now - new Date(data.educations[0].endTime).getTime()
            const workExperience = Math.floor(workMilli / (365 * 24 * 3600 * 1000)) + 1
            data.workExperience = workExperience
          } else {
            data.workExperience = '0'
          }
          this.resume = data
          this.initWordList()
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    },
    initWordList() {
      if (this.resume.skillLabels) {
        this.wordList = this.resume.skillLabels.map(item => {
          return {
            name: item.skillName,
            value: item.id
          }
        })
      }
    },
    handleAddPost(personId) {
      this.personId = personId
      this.showAddPost = true
    },
    handleAddCallback(row) {
      this.toggleLoading(true)
      this.$post(this.urls.postSave, { personId: this.personId, sid: row.sid }).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          this.$message.success('添加成功')
          this.showAddPost = false
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    },
    // 入库
    entryStorage() {
      this.toggleLoading(true)
      this.$post(this.urls.resumeEntry, { personId: this.personId }).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          this.$emit('update')
          this.$message.success('入库成功')
          this.handleBack()
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./components/css/resume.scss";
.resume {
  // padding: px2vw(10) px2vw(20) px2vw(20) px2vw(20);
  max-width: 1280px;
  margin: auto;
}
.in-storage-parent {
  cursor: pointer;
  margin-top: 10px;
  .in-storage {
    font-size: 20px;
  }
  .storage-text:hover {
    color: rgb(0, 198, 154);
  }
}
.suspend-view {
  width: 300px;
  right: 5px;
  top: 170px;
  position: fixed;
  z-index: 2;
}
.slide-fade-enter-active,
.slide-fade-leave-active,
.fade-leave-active,
.fade-enter-active {
  transition: all 0.3s;
}
.slide-fade-enter,
.slide-fade-leave-to,
.fade-enter,
.fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.breadcrumb{
 padding: 20px 0;
    // border-bottom: 1px solid #bdbdbd;
    width: 100%;
    cursor: pointer;
}
</style>
