<!--
   User: Liu Yin
   Date: 2020/4/7
   Description:工作经验
 -->
<template>
  <article class="experience-container">
    <div class="module-title-container">
      <div class="module-title-left" />
      <p class="module-title">工作经历</p>
      <i v-if="handleEnable" class="el-icon-plus edit-btn" @click="add">添加</i>
    </div>
    <ul v-if="!showEdit" class="preview-module">
      <li v-for="(item,index) in resume.workExperiences" :key="index" class="module-content-container">
        <section class="row-title">
          <p class="module-title-grey">{{ item.workCompany }}</p>
          <span class="content-grey suffix">{{ item.startTime }} 至 {{ item.endTime }}</span>
          <span class="content-grey suffix">{{ getDiffYear(item.startTime,item.endTime) }}</span>
          <div v-if="handleEnable" class="edit-btn">
            <i class="el-icon-edit" @click="edit(item)">编辑</i>
            <i class="el-icon-delete btn-remove" @click="remove(resume.workExperiences,urls.removeWork,item.id)">删除</i>
          </div>
        </section>
        <section class="row">
          <span class="grey-text">薪资：</span>
          <span class="grey-text">{{ formatterSalary(item.minSalary) }} - {{ formatterSalary(item.maxSalary) }}</span>
        </section>
        <section class="row">
          <span class="grey-text">职位：</span>
          <span class="deep-grey-text">{{ item.workPosition }}</span>
          <div class="vertical-division" />
          <span class="grey-text">所属行业：</span>
          <span class="deep-grey-text">{{ formatterIndustry(item.workIndustry) }}</span>
        </section>
        <div style="overflow: hidden">
          <p class="row-title module-title-grey">工作内容</p>
          <p class="row-content content-grey">{{ item.workDetails }}</p>
        </div>
      </li>
    </ul>
    <section v-else>
      <el-form
        ref="form"
        class="edit-container"
        label-width="130px"
        :model="formData"
        :rules="formRules"
        label-position="right"
      >
        <el-row :gutter="25">
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="公司名称：" prop="workCompany">
              <el-input
                v-model="formData.workCompany"
                placeholder="请输入公司名称"
                maxlength="32"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="所属行业：" prop="personIndustrySelect">
              <el-cascader
                v-model="formData.personIndustrySelect"
                :options="industryOptions"
                :props="{ expandTrigger: 'hover',multiple:true }"
                :show-all-levels="false"
                mutiple
                placeholder="请输入期望行业"
                @change="handleChangeIndustry"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="职位名称：" prop="workPosition">
              <el-input
                v-model="formData.workPosition"
                placeholder="请输入职位名称"
                maxlength="32"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="25">
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="薪资范围：" prop="selectSalary">
              <el-select v-model="formData.selectSalary" placeholder="请选择薪资" value="" value-key="name">
                <el-option
                  v-for="item in salaryList"
                  :key="item.name"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="在职时间：" prop="timeRange">
              <el-date-picker
                v-model="formData.timeRange"
                type="daterange"
                value-format="yyyy-MM-dd"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="工作内容：" prop="workDetails">
          <el-input
            v-model="formData.workDetails"
            placeholder="请输入工作内容"
            type="textarea"
            maxlength="512"
          />
        </el-form-item>
        <div class="edit-footer">
          <el-button @click="closeEdit">
            取消
          </el-button>
          <el-button type="primary" @click="validateForm()">
            确定
          </el-button>
        </div>
      </el-form>
    </section>
  </article>
</template>

<script>
import talentMixin from '../../../../../../utils/mixins/talentMixin'
import dictMixin from '../../../../../../utils/mixins/dictMixin'
import resumeEditMixin from '../../../../../../utils/mixins/resumeEditMixin'
export default {
  mixins: [dictMixin, resumeEditMixin, talentMixin],
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          // 此条为设置禁止用户选择今天之前的日期，包含今天。
          // return time.getTime() <= (Date.now()-(24 * 60 * 60 * 1000));
          // 此条为设置禁止用户选择今天之前的日期，不包含今天。
          return time.getTime() >= (Date.now())
        }
      },
      industryOptions: this.converseData(this.dictObj.enter_industry),
      formData: {
        workCompany: '',
        startTime: '',
        endTime: '',
        minSalary: '',
        maxSalary: '',
        workPosition: '',
        positionType: '',
        workLevel: '',
        workIndustry: '',
        msg: '',
        personId: this.personId,
        workDetails: '',
        timeRange: [],
        selectSalary: null,
        // 选中的行业
        personIndustrySelect: []
      },
      formRules: {
        workCompany: [
          { required: true, message: '请输入公司名称', trigger: ['blur', 'change'] }
        ],
        timeRange: [
          { required: true, message: '请选择在职时间', trigger: ['blur', 'change'] }
        ],
        workPosition: [
          { required: true, message: '请输入职位名称', trigger: ['blur', 'change'] }
        ],
        selectSalary: [
          { required: true, message: '请选择薪资范围', trigger: ['blur', 'change'] }
        ],
        personIndustrySelect: [
          { required: true, message: '请选择所属行业', trigger: ['blur', 'change'] }
        ],
        workDetails: [
          { required: true, message: '请输入工作内容', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  watch: {
    dictObj: function(val, oldVal) {
      this.industryOptions = this.converseData(this.dictObj.enter_industry)
    }
  },
  mounted() {
  },
  methods: {
    // add() {
    //   this.showEdit = true
    //   this.isAdd=true
    // },
    getDiffYear(startTime, endTime) {
      if (!startTime || !endTime) return ''
      const diffTime = new Date(endTime).getTime() - new Date(startTime).getTime()

      const diffMonth = Math.ceil(diffTime / 1000 / 60 / 60 / 24 / 30)
      if (diffMonth < 12) {
        return '(' + diffMonth + '月)'
      }
      const diffYear = Math.floor(diffMonth / 12)
      const remainderMonth = diffMonth % 12
      return `( ${diffYear}年 ${remainderMonth}月)`
    },
    edit(item) {
      this.isAdd = false
      if (item.startTime && item.endTime) {
        item.timeRange = [item.startTime, item.endTime]
      }
      const salary = this.salaryList.find(salary => {
        if (item.minSalary) {
          if (salary.minSalary === Math.floor(item.minSalary)) {
            return true
          }
        } else if (item.maxSalary) {
          if (salary.maxSalary === Math.floor(item.maxSalary)) {
            return true
          }
        }
      })

      // 拼接行业选择的数据
      if (item.workIndustry) {
        this.formData.personIndustrySelect = []
        const industryList = item.workIndustry.split(',')
        industryList.forEach(code => {
          this.formData.personIndustrySelect.push(this.getIndustryOption(code))
        })
      }

      item.selectSalary = salary
      for (const i in item) {
        this.formData[i] = item[i]
      }
      this.showEdit = true
    },
    // 根据行业code查询父子关联的option
    getIndustryOption(code) {
      const areaList = this.dictObj.enter_industry
      const matchChild = areaList.find(item => {
        if (code === item.dictCode) {
          return true
        }
      })
      if (!matchChild) return []
      const parentId = matchChild.parentId
      if (parentId !== 0) {
        const matchParent = areaList.find(item => {
          if (parentId === item.dictId) {
            return true
          }
        })
        return [matchParent.dictCode, matchChild.dictCode]
      }
    },
    handleChangeIndustry(val) {
      if (this.formData.personIndustrySelect.length > 3) {
        this.$message.warning('最多选择3个行业')
        this.formData.personIndustrySelect = this.formData.personIndustrySelect.splice(0, 3)
      }
    },
    submitForm() {
      this.formData.minSalary = this.formData.selectSalary.minSalary
      this.formData.maxSalary = this.formData.selectSalary.maxSalary
      if (new Date().getTime() < new Date(this.formData.timeRange[0]).getTime()) {
        this.$message.warning('在职开始时间不能大于当前时间')
        return
      }
      if (new Date().getTime() < new Date(this.formData.timeRange[1]).getTime()) {
        this.$message.warning('在职结束时间不能大于当前时间')
        return
      }

      this.formData.startTime = this.formData.timeRange[0]
      this.formData.endTime = this.formData.timeRange[1]
      let workIndustry = ''
      this.formData.personIndustrySelect.forEach((item, index) => {
        if (index === this.formData.personIndustrySelect.length - 1) {
          workIndustry = workIndustry.concat(item[1])
        } else {
          workIndustry = workIndustry.concat(item[1] + ',')
        }
      })
      this.formData.workIndustry = workIndustry
      this.$post(this.urls.saveWork, this.formData).then(result => {
        if (result.code === 'SUCCESS') {
          this.itemCommitSuccess(this.resume.workExperiences, this.formData)
        } else {
          this.$message.error('保存失败')
        }
      }).catch(() => {
        this.$message.error('提交失败')
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "../css/resume";

</style>
