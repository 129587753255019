/**
 * author Liu Yin
 * date 2020/4/3
 * Description:字典转化
 */
export default {
  props: {
    // 字典数据
    dictObj: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    // 格式化行业
    formatterIndustry() {
      return function(value) {
        if (!value) return value
        const industryList = value.split(',')
        let industryNames = ''
        industryList.forEach((code, index) => {
          const name = this.formatterData(this.dictObj.enter_industry, code)
          if (index < industryList.length - 1) {
            industryNames = industryNames.concat(name + '/')
          } else {
            industryNames = industryNames.concat(name)
          }
        })
        return industryNames
      }
    },
    // 转化城市
    formatterCity() {
      return function(value) {
        return this.formatterData(this.dictObj.area, value)
      }
    },
    // 格式化学历
    formatterEducation() {
      return function(value) {
        return this.formatterData(this.dictObj.education, value)
      }
    },
    // 格式化求职状态
    formatterJobStatus() {
      return function(value) {
        return this.formatterData(this.dictObj.job_status, value)
      }
    },
    // 格式化工作性质
    formatterWorkNature() {
      return function(value) {
        return this.formatterData(this.dictObj.work_nature, value)
      }
    },
    // 格式化金额
    formatterSalary() {
      return function(value) {
        if (!value) {
          return '面议'
        }
        if (value >= 1000) {
          const money = value / 1000
          return parseInt(money) + 'K'
        } else {
          return value
        }
      }
    }
  },
  methods: {
    // 格式化字典数据
    formatterData(list, value) {
      if (!list || !value) {
        return value
      }
      const match = list.find(item => {
        if (item.dictCode === value) {
          return true
        }
      })
      return match ? match.dictValue : ''
    }
  }
}
