<!--
   User: liuyin
   Date: 2019/9/21 15:57
   Description: 浮动标签展示
 -->
<template>
  <ul class="float-label">
    <li
      v-for="(value,key,index) in labelMap"
      :key="index"
      class="float-label-item"
    >
      <div class="float-label-item-text font-14">
        {{ value.value }}
        <i class="el-icon-close float-label-item-del" @click="handleDel(value,key)" />
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'FloatLabel',
  props: {
    labelMap: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  methods: {
    // 删除
    handleDel(value, key) {
      this.$delete(this.labelMap, key)
      this.$emit('handlerRemove', value)
    }
  }
}
</script>
<style lang="scss" scoped>
  .float-label{
    display: flex;
    flex-wrap: wrap;
    .float-label-item{
      background-color:#F1F1F1;
      margin-right:5px;
      margin-top:5px;
      border-radius: 4px;
      .float-label-item-text{
        text-align:center;
        line-height:10px;
        padding:7px 10px;
        word-break: break-all;
      }
      .float-label-item-text:hover{
        color: #409eff;
      }
      .float-label-item-del{
        cursor: pointer;
      }
    }
  }
</style>
