<!--
   User: Liu Yin
   Date: 2020/4/7
   Description:自我评价
 -->
<template>
  <article>
    <div class="module-title-container">
      <div class="module-title-left"></div>
      <p class="module-title">自我评价</p>
      <i v-if="handleEnable" class="el-icon-edit edit-btn" @click="edit(resume.personAssess)">编辑</i>
    </div>
    <article v-if="!showEdit" class="module-content-container">
      <span class="content-grey">{{ resume.personAssess }}</span>
    </article>
    <section v-else>
      <el-form
        ref="form"
        class="edit-container"
        label-width="120px"
        :model="formData"
        :rules="formRules"
        label-position="right"
      >
        <el-form-item label="自我评价：" prop="personAssess">
          <el-input
            v-model="formData.personAssess"
            placeholder="请输入自我评价"
            type="textarea"
            maxlength="512"
          />
        </el-form-item>

        <div class="edit-footer">
          <el-button @click="closeEdit">
            取消
          </el-button>
          <el-button type="primary" @click="validateForm()">
            确定
          </el-button>
        </div>
      </el-form>
    </section>
  </article>
</template>

<script>
import resumeEditMixin from '../../../../../../utils/mixins/resumeEditMixin'
export default {
  mixins: [resumeEditMixin],
  data() {
    return {
      formData: {
        personAssess: '',
        personId: this.personId
      },
      formRules: {
        personAssess: [
          { required: true, message: '请输入自我评价', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    edit(personAssess) {
      this.formData.personAssess = personAssess
      this.showEdit = true
    },
    submitForm() {
      this.$post(this.urls.savePerson, this.formData).then(result => {
        if (result.code === 'SUCCESS') {
          this.objCommitSuccess(this.resume, this.formData)
        } else {
          this.$message.error('保存失败')
        }
      }).catch(() => {
        this.$message.error('提交失败')
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "../css/resume";

</style>
