<!--
   User: Liu Yin
   Date: 2020/4/7
   Description:资格证书
 -->
<template>
  <article>
    <div class="module-title-container">
      <div class="module-title-left" />
      <p class="module-title">资格证书</p>
      <i v-if="handleEnable" class="el-icon-plus edit-btn" @click="add">添加</i>
    </div>
    <ul v-if="!showEdit">
      <li v-for="item in resume.certificates" :key="item.id+'certificate'" class="module-content-container">
        <section class="row-title">
          <span class="brief-introduction">{{ item.certificateName }}</span>
          <span class="content-grey" style="margin-left: 20px">{{ item.startTime }} 至 {{ item.endTime }}</span>
          <div v-if="handleEnable" class="edit-btn">
            <i class="el-icon-edit" @click="edit(item)">编辑</i>
            <i class="el-icon-delete btn-remove" @click="remove(resume.certificates,urls.removeCertificate,item.id)">删除</i>
          </div>
        </section>
      </li>
    </ul>
    <section v-else>
      <el-form
        ref="form"
        class="edit-container"
        label-width="130px"
        :model="formData"
        :rules="formRules"
        label-position="right"
      >
        <el-row :gutter="25">
          <el-col :xs="24" :sm="12" :lg="12">
            <el-form-item label="证书名称：" prop="certificateName">
              <el-input
                v-model="formData.certificateName"
                placeholder="请输入证书名称"
                maxlength="32"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="12">
            <el-form-item label="时间：" prop="timeRange">
              <el-date-picker
                v-model="formData.timeRange"
                type="datetimerange"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="edit-footer">
          <el-button @click="closeEdit">
            取消
          </el-button>
          <el-button type="primary" @click="validateForm()">
            确定
          </el-button>
        </div>
      </el-form>
    </section>
  </article>
</template>

<script>
import resumeEditMixin from '../../../../../../utils/mixins/resumeEditMixin'
export default {
  mixins: [resumeEditMixin],
  data() {
    return {
      formData: {
        certificateName: '',
        startTime: '',
        endTime: '',
        personId: this.personId,
        timeRange: []
      },
      formRules: {
        certificateName: [
          { required: true, message: '请输入证书名称', trigger: 'blur' }
        ],
        timeRange: [
          { required: true, message: '请选择时间', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    edit(item) {
      if (item.startTime && item.endTime) {
        item.timeRange = [item.startTime, item.endTime]
      }
      for (const i in item) {
        this.formData[i] = item[i]
      }
      this.showEdit = true
    },
    submitForm() {
      this.formData.startTime = this.formData.timeRange[0]
      this.formData.endTime = this.formData.timeRange[1]
      this.$post(this.urls.saveCertificate, this.formData).then(result => {
        if (result.code === 'SUCCESS') {
          this.itemCommitSuccess(this.resume.certificates, this.formData)
        } else {
          this.$message.error('保存失败')
        }
      }).catch(() => {
        this.$message.error('提交失败')
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "../css/resume";

</style>
