<!--
   User: Liu Yin
   Date: 2020/4/7
   Description:教育经历
 -->
<template>
  <article>
    <div class="module-title-container">
      <div class="module-title-left" />
      <p class="module-title">教育经历</p>
      <i v-if="handleEnable" class="el-icon-plus edit-btn" @click="add">添加</i>
    </div>
    <ul v-if="!showEdit">
      <li v-for="item in resume.educations" :key="item.id+'education'" class="module-content-container">
        <section class="row-title">
          <p class="module-title-grey">{{ item.schoolName }}</p>
          <span class="content-grey suffix">{{ item.startTime }} 至 {{ item.endTime }}</span>
          <div v-if="handleEnable" class="edit-btn">
            <i class="el-icon-edit" @click="edit(item)">编辑</i>
            <i class="el-icon-delete btn-remove" @click="remove(resume.educations,urls.removeEducation,item.id)">删除</i>
          </div>
        </section>
        <section class="row-title">
          <span class="deep-grey-text">{{ item.schoolMajor }}</span>
          <div v-if="item.schoolMajor&&item.educational" class="bias" />
          <span class="deep-grey-text">{{ formatterEducation(item.educational) }}</span>
        </section>
      </li>
    </ul>
    <section v-else>
      <el-form
        ref="form"
        class="edit-container"
        label-width="130px"
        :model="formData"
        :rules="formRules"
        label-position="right"
      >
        <el-row :gutter="25">
          <el-col :xs="24" :sm="12" :lg="12">
            <el-form-item label="学校名称：" prop="schoolName">
              <el-input
                v-model="formData.schoolName"
                placeholder="请输入学校名称"
                maxlength="32"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="12">
            <el-form-item label="教育时间：" prop="timeRange">
              <el-date-picker
                v-model="formData.timeRange"
                type="daterange"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="25">
          <el-col :xs="24" :sm="12" :lg="12">
            <el-form-item label="专业名称：" prop="schoolMajor">
              <el-input
                v-model="formData.schoolMajor"
                placeholder="请输入专业名称"
                maxlength="32"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="12">
            <el-form-item label="学历：" prop="educational">
              <el-select v-model="formData.educational" placeholder="请选择学历" value="" value-key="dictCode">
                <el-option
                  v-for="item in educationList"
                  :key="item.dictCode"
                  :label="item.dictValue"
                  :value="item.dictCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="edit-footer">
          <el-button @click="closeEdit">
            取消
          </el-button>
          <el-button type="primary" @click="validateForm()">
            确定
          </el-button>
        </div>
      </el-form>
    </section>
  </article>
</template>

<script>
import resumeEditMixin from '../../../../../../utils/mixins/resumeEditMixin'
import dictMixin from '../../../../../../utils/mixins/dictMixin'
export default {
  mixins: [resumeEditMixin, dictMixin],
  data() {
    return {
      formData: {
        schoolName: '',
        startTime: '',
        endTime: '',
        schoolMajor: '',
        positionType: '',
        personId: this.personId,
        educational: '',
        timeRange: [],
        // 选中的行业
        personIndustrySelect: []
      },
      educationList: this.dictObj.education,
      formRules: {
        schoolName: [
          { required: true, message: '请输入学校名称', trigger: 'blur' }
        ],
        timeRange: [
          { required: true, message: '请选择教育时间', trigger: 'blur' }
        ],
        schoolMajor: [
          { required: true, message: '请输入项目职位', trigger: 'blur' }
        ],
        educational: [
          { required: true, message: '请选择学历', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    dictObj: function(val, oldVal) {
      this.educationList = this.dictObj.education
    }
  },
  methods: {
    edit(item) {
      this.isAdd = false
      if (item.startTime && item.endTime) {
        item.timeRange = [item.startTime, item.endTime]
      }
      for (const i in item) {
        this.formData[i] = item[i]
      }
      this.showEdit = true
    },
    submitForm() {
      this.formData.startTime = this.formData.timeRange[0]
      this.formData.endTime = this.formData.timeRange[1]
      this.$post(this.urls.saveEducation, this.formData).then(result => {
        if (result.code === 'SUCCESS') {
          this.itemCommitSuccess(this.resume.educations, this.formData)
        } else {
          this.$message.error('保存失败')
        }
      }).catch(() => {
        this.$message.error('提交失败')
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "../css/resume";

</style>
