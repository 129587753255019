<!--
   User: Liu Yin
   Date: 2020/4/7
   Description:专业技能
 -->
<template>
  <article>
    <div class="module-title-container">
      <div class="module-title-left"></div>
      <p class="module-title">专业技能</p>
      <i v-if="handleEnable" class="el-icon-plus edit-btn" @click="addSkill">添加</i>
    </div>
    <!-- <world-cloud v-if="!handleEnable" :word-list="wordList"></world-cloud>-->
    <ul v-if="!showEdit">
      <li v-for="item in resume.skillLabels" :key="item.id+'skill'" class="module-content-container">
        <section class="row-title">
          <span class="brief-introduction">{{ item.skillName }}</span>
          <div v-if="handleEnable" class="edit-btn">
            <i class="el-icon-edit" @click="edit(item)">编辑</i>
            <i class="el-icon-delete btn-remove" @click="remove(resume.skillLabels,urls.skillRemove,item.id)">删除</i>
          </div>
        </section>
      </li>
    </ul>
    <section v-else>
      <el-form
        ref="form"
        class="edit-container"
        label-width="130px"
        :model="formData"
        :rules="formRules"
        label-position="right"
      >
        <el-form-item label="专业技能：">
          <ul style="display: flex;flex-wrap: wrap" class="input-parent">
            <li v-for="(item,index) in skillList" :key="index" class="position-item">
              <el-input
                :id="'skill'+index"
                v-model="item.skillName"
                placeholder="请输入技能"
                maxlength="32"
              >
                <i slot="suffix" class="el-input__icon el-icon-delete" @click="removePosition(index)"></i>
              </el-input>
            </li>
            <el-button v-if="isAdd" type="info" size="mini" class="el-icon-plus" @click="addPosition">添加技能</el-button>
          </ul>
        </el-form-item>

        <div class="edit-footer">
          <el-button @click="closeEdit">
            取消
          </el-button>
          <el-button type="primary" @click="validateForm()">
            确定
          </el-button>
        </div>
      </el-form>
    </section>

  </article>
</template>

<script>
import resumeEditMixin from '../../../../../../utils/mixins/resumeEditMixin'
export default {
  mixins: [resumeEditMixin],
  data() {
    return {
      formData: {
        personId: this.personId
      },
      skillList: [],
      formRules: {
      },
      isAdd: false,
      wordList: []
    }
  },
  methods: {
    initWordList() {
      if (this.resume.skillLabels) {
        this.wordList = this.resume.skillLabels.map(item => {
          return {
            name: item.skillName,
            value: item.id
          }
        })
      }
    },
    edit(item) {
      this.skillList = [item]
      this.showEdit = true
      this.isAdd = false
    },
    addSkill() {
      this.skillList = []
      this.showEdit = true
      this.isAdd = true
    },
    submitForm() {
      if (this.skillList.length === 0) {
        this.$message.warning('请填写完整')
        return
      }
      for (let i = 0; i < this.skillList.length; i++) {
        const skillName = this.skillList[i].skillName
        if (!skillName) {
          this.$message.error('请填写完整')
          return
        }
        const existList = this.skillList.filter(item => {
          if (item.skillName === skillName) return true
        })
        if (existList.length > 1) {
          this.$message.error(skillName + '技能重复')
          return
        }
      }
      this.$post(this.urls.skillSave, this.skillList).then(result => {
        if (result.code === 'SUCCESS') {
          this.itemCommitSuccess(this.resume.skillLabels, this.skillList[0])
        } else {
          this.$message.error('保存失败')
        }
      }).catch(() => {
        this.$message.error('提交失败')
      })
    },
    addPosition() {
      this.skillList.push({ skillName: '', personId: this.personId })
      this.$nextTick(() => {
        const dom = document.getElementById('skill' + (this.skillList.length - 1))
        dom.focus()
      })
    },
    removePosition(index) {
      this.skillList.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "../css/resume";

  .el-input{
    width: px2vw(400)
  }

</style>
