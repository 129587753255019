<!--
   User: Liu Yin
   Date: 2020/4/3
   Description:
 -->
<template>
  <!--个人信息-->
  <article>
    <article class="preview-module">
      <section class="personal-info">
        <img class="head-picture" :src="resume.personPicture?resume.personPicture:headHolder" alt="" @error="handleError">
        <article class="personal-content">
          <section class="person-item">
            <h4 class="type-title">候选人 :</h4>
            <p class="person-item-content">{{ resume.personName }}</p>
            <p class="person-item-content">{{ resume.personSex==='0'?'女':'男' }}</p>
            <p class="person-item-content">{{ resume.age }}岁</p>
            <p>户籍: {{ resume.personArcity }}</p>
          </section>
          <section class="person-item">
            <h4 class="type-title">当前概况:</h4>
            <!-- <p class="person-item-content">{{ resume.personPosition }}</p>-->
            <p class="person-item-content">{{ resume.workExperience }}年工作经验</p>
            <p v-if="resume.personLanguages" class="person-item-content">{{ resume.personLanguages }}</p>
            <p v-if="abroadStudyChecked" class="person-item-content">有海外留学经历</p>
            <p v-if="abroadWorkChecked" class="person-item-content">有海外工作经历</p>
            <p>{{ formatterJobStatus(resume.personState) }}</p>
          </section>
          <section class="person-item">
            <h4 class="type-title">教育信息:</h4>
            <p class="person-item-content">{{ resume.schoolName }}</p>
            <p class="person-item-content">{{ resume.personMajor }}</p>
            <p class="person-item-content">{{ formattingField(educationList, resume.personEducation, 'dictCode', 'dictValue') }}</p>
            <p class="person-item-content">{{ formattingField(collegeType, resume.collegeType, 'dictCode', 'dictValue') }}</p>
            <p v-if="isRecruitChecked">统招</p>
          </section>
          <!-- <section class="person-item">
            <h4 class="type-title">联系方式:</h4>
            <p class="person-item-content">{{ resume.personMobile }}</p>
            <p>{{ resume.personMail }}</p>
          </section> -->
          <section class="person-item">
            <h4 class="type-title">期望工作地:</h4>
            <p>{{ resume.expectCity }}</p>
          </section>
          <section class="person-item">
            <h4 class="type-title">期望行业:</h4>
            <p>{{ formatterIndustry(resume.personIndustry) }}</p>
          </section>
          <section class="person-item">
            <h4 class="type-title">期望职位:</h4>
            <p>{{ resume.personPosition }}</p>
          </section>
          <section class="person-item">
            <h4 class="type-title">期望薪资:</h4>

            <p>{{ getSalary(resume.minSalary,resume.maxSalary) }}</p>
          </section>
          <section class="person-item">
            <h4 class="type-title">工作性质:</h4>
            <p>{{ formatterWorkNature(resume.personWork) }}</p>
          </section>
        </article>
      </section>
    </article>
  </article>
</template>

<script>
import dictMixin from '../../../../../../utils/mixins/dictMixin'
import boyHolder from '../../../../../../assets/images/boy.jpg'
import girlHolder from '../../../../../../assets/images/girl.jpg'
import talentMixin from '../../../../../../utils/mixins/talentMixin'
import resumeEditMixin from '../../../../../../utils/mixins/resumeEditMixin'
import { clone } from '@/utils/tools'

export default {
  mixins: [dictMixin, talentMixin, resumeEditMixin],
  props: {
    organizeList: {
      type: Array,
      default() {
        return []
      }
    },
    originOrganizeList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      cityOptions: this.converseData(this.dictObj.area),
      censusOptions: [],
      industryOptions: this.converseData(this.dictObj.enter_industry),
      headHolder: '',
      formData: {
        schoolName: '',
        // 姓名
        personName: '',
        // 电话
        personMobile: '',
        // 期望城市
        expectCity: '',
        // 选择的期望城市
        expectCitySelect: [],
        // 籍贯
        personArcity: '',
        // 选择的籍贯城市
        personArcitySelect: [],
        // 性别
        personSex: '1',
        // 出生日期
        birthdate: '',
        // 开始工作时间
        workdate: '',
        // 个人邮箱
        personMail: '',
        // 求职状态
        personState: '',
        // 期望行业
        personIndustry: '',
        // 选中的期望行业
        personIndustrySelect: [],
        // 期望职位
        personPosition: '占位',
        // 工作性质
        personWork: '',
        // 选择的薪资
        selectSalary: null,
        // 学历
        personEducation: '',
        // 头像
        personPicture: '',
        // 专业
        personMajor: null,
        // 学校类型
        collegeType: '',
        // 是否统招  0非统招  1统招
        isRecruit: 0,
        // 是否海外工作  0非  1
        abroadWork: 0,
        // 是否海外留学  0非  1
        abroadStudy: 0,
        // 外语
        personLanguages: '',
        personId: this.personId
      },
      positionList: [],
      uploadUrl: this.urls.BASE_URL + this.urls.uploadFiles,

      // 选择是否统招
      isRecruitChecked: false,
      // 选择是否海外经历
      abroadWorkChecked: false,
      // 选择是否海外留学
      abroadStudyChecked: false,
      jobStatusList: this.dictObj.job_status,
      workNature: this.dictObj.work_nature,
      educationList: this.dictObj.education,
      collegeType: this.dictObj.college_type,
      pickerOptions: {
        disabledDate(time) {
          // 此条为设置禁止用户选择今天之前的日期，包含今天。
          // return time.getTime() <= (Date.now()-(24 * 60 * 60 * 1000));
          // 此条为设置禁止用户选择今天之前的日期，不包含今天。
          return time.getTime() >= (Date.now())
        }
      }
    }
  },
  computed: {
    // getEducationInfo() {
    //   return this.formattingField(this.educationList, this.resume.personEducation, 'dictCode', 'dictValue')
    // }
  },
  watch: {
    dictObj: function(val, oldVal) {
      this.cityOptions = this.converseData(this.dictObj.area)
      const area = clone(this.dictObj.area)
      area.splice(0, 2)
      this.censusOptions = this.converseData(area)
      this.industryOptions = this.converseData(this.dictObj.enter_industry)
      this.jobStatusList = this.dictObj.job_status
      this.workNature = this.dictObj.work_nature
      this.educationList = this.dictObj.education
      this.collegeType = this.dictObj.college_type
    },
    resume() {
      // 回显是否统招
      this.isRecruitChecked = this.resume.isRecruit === 1
      // 回显是否海外工作
      this.abroadWorkChecked = this.resume.abroadWork === 1
      // 回显是否海外留学
      this.abroadStudyChecked = this.resume.abroadStudy === 1
      this.getHolderImage()
      this.resume.personName = this.formatName(this.resume.personName)
    }
  },
  created() {
    document.body.scrollTop = 0
    // console.log('this.dictObj.area', this.dictObj.area)
    if (!this.personId) {
      this.showEdit = true
    }
    if (this.resume) {
      this.getHolderImage()
    }
  },
  methods: {
    formatName(str) {
      return str.substring(0, 1) + new Array(str.length).join('*')
    },
    // 图片链接失效/错误给默认图
    handleError(event) {
      const img = event.srcElement
      img.src = this.headHolder
      img.onerror = null // 防止闪图
    },
    getSalary(minSalary, maxSalary) {
      if (!minSalary) {
        return '薪资面议'
      }
      return this.formatterSalary(minSalary) + '-' + this.formatterSalary(maxSalary)
    },
    closeEdit() {
      if (this.personId) {
        this.showEdit = false
      } else {
        this.$emit('exit')
      }
    },
    changesCheckBox(b, type) {
      const value = b ? 1 : 0
      switch (type) {
        case 1:
          this.formData.isRecruit = value
          break
        case 2:
          this.formData.abroadWork = value
          break
        case 3:
          this.formData.abroadStudy = value
          break
      }
    },
    getHolderImage() {
      const sex = this.resume.personSex
      if (sex === '0') {
        this.headHolder = girlHolder
      } else {
        this.headHolder = boyHolder
      }
    },
    changeSex(sex) {
      if (sex === '0') {
        this.headHolder = girlHolder
      } else {
        this.headHolder = boyHolder
      }
    },
    edit(item) {
      if (JSON.stringify(this.resume) === '{}') {
        this.showEdit = true
        return
      }
      delete item.certificates
      delete item.educations
      delete item.prizes
      delete item.projectExperiences
      delete item.workExperiences

      // 根据薪资code选出对应的薪资
      const salary = this.salaryList.find(salary => {
        if (item.minSalary) {
          if (salary.minSalary === Math.floor(item.minSalary)) {
            return true
          }
        } else if (item.maxSalary) {
          if (salary.maxSalary === Math.floor(item.maxSalary)) {
            return true
          }
        } else {
          return salary.minSalary === item.minSalary
        }
      })
      item.selectSalary = salary
      // 切割期望职位
      if (item.personPosition) {
        this.positionList = []
        const positionList = item.personPosition.split(',')
        positionList.forEach(position => {
          this.positionList.push({ value: position })
        })
      }
      // 拼接期望城市选择的编码
      this.formData.expectCitySelect = this.getCityOptions(item.expectCity)
      // 拼接籍贯选择的城市
      this.formData.personArcitySelect = this.getCityOptions(item.personArcity)
      // 拼接行业选择的数据
      if (item.personIndustry) {
        this.formData.personIndustrySelect = []
        const industryList = item.personIndustry.split(',')
        industryList.forEach(code => {
          this.formData.personIndustrySelect.push(this.getIndustryOption(code))
        })
      }
      for (const i in item) {
        this.formData[i] = item[i]
      }
      this.formData.organizeSelect = this.getOrganizeSelect(item.organId)
      this.showEdit = true
    },

    getOrganizeSelect(orgId) {
      const list = this.matchOrganize(orgId, [])
      return list
    },
    matchOrganize(orgId, selectOrganize) {
      const match = this.originOrganizeList.find(item => {
        if (parseInt(item.orgId) === orgId) {
          return true
        }
      })
      if (match) {
        selectOrganize.unshift(orgId)
        const parentId = match.parentId
        if (parentId && parentId !== 0) {
          return this.matchOrganize(parentId, selectOrganize)
        } else {
          return selectOrganize
        }
      } else {
        return selectOrganize
      }
    },
    // 根据城市code查询父子关联的option
    getCityOptions(cityName) {
      const areaList = this.dictObj.area
      const matchChild = areaList.find(item => {
        if (cityName === item.dictValue && item.parentId !== 0) {
          return true
        }
      })
      if (!matchChild) return []
      const parentId = matchChild.parentId
      if (parentId !== 0) {
        const matchParent = areaList.find(item => {
          if (parentId === item.dictId) {
            return true
          }
        })
        return [matchParent.dictCode, matchChild.dictCode]
      } else {
        return [matchChild.dictCode, matchChild.dictCode]
      }
    },
    // 根据行业code查询父子关联的option
    getIndustryOption(code) {
      const areaList = this.dictObj.enter_industry
      const matchChild = areaList.find(item => {
        if (code === item.dictCode) {
          return true
        }
      })
      if (!matchChild) return []
      const parentId = matchChild.parentId
      if (parentId !== 0) {
        const matchParent = areaList.find(item => {
          if (parentId === item.dictId) {
            return true
          }
        })
        return [matchParent.dictCode, matchChild.dictCode]
      }
    },
    handleAvatarSuccess(res, file) {
      this.toggleLoading(false)
      this.formData.personPicture = res.data.filePath
    },
    handleUploadError() {
      this.$message.error('上传失败')
      this.toggleLoading(false)
    },
    beforeAvatarUpload(file) {
      const isJPG = (file.type).indexOf('image/') !== -1
      const maxSize = file.size / 1024 / 1024 < 6

      if (!isJPG) {
        this.$message.error('只能上传图片!')
      }
      if (!maxSize) {
        this.$message.error('上传头像图片大小不能超过 6MB!')
      }
      const canUpload = isJPG && maxSize
      if (canUpload) this.toggleLoading(true)
      return canUpload
    },
    handleChangeCity(val) {
      // this.formData.expectCity = val[1]
      // console.log(this.formData.personArcitySelect)
    },
    handleChangeIndustry(val) {
      const industrySelect = []
      if (val.length > 0) {
        val.forEach(item => {
          industrySelect.push(item[0])
        })
      }
      const match = industrySelect.filter(item => {
        if (industrySelect[0] !== item) {
          return item
        }
      })
      let filteredArr = []
      let indexes = []
      filteredArr = industrySelect.filter(x => industrySelect[0] === x)
      indexes = filteredArr.map(x => industrySelect.indexOf(x))
      if (indexes.length > 0 && match.length > 0) {
        this.$message.warning('一级菜单为单选,请确认选项是否正确')
        this.formData.personIndustrySelect = this.formData.personIndustrySelect.splice(0, indexes.length)
      }
      if (this.formData.personIndustrySelect.length > 3) {
        this.$message.warning('最多选择3个行业')
        this.formData.personIndustrySelect = this.formData.personIndustrySelect.splice(0, 3)
      }
      this.$forceUpdate()
      // if (this.formData.personIndustrySelect.length > 3) {
      //   this.$message.warning('最多选择3个行业')
      //   this.formData.personIndustrySelect = this.formData.personIndustrySelect.splice(0, 3)
      // }
    },
    addPosition() {
      if (this.positionList.length > 0 && this.positionList[this.positionList.length - 1].value === '') {
        return
      }
      if (this.positionList.length >= 6) {
        this.$message.warning('最多添加6个职位')
        return
      }
      this.positionList.push({ value: '' })
      this.$nextTick(() => {
        const dom = document.getElementById('position' + (this.positionList.length - 1))
        dom.focus()
      })
    },
    removePosition(index) {
      this.positionList.splice(index, 1)
    },
    // 提交数据前校验表单
    validateForm() {
      // 校验格式化职位
      let positions = ''
      for (let i = 0; i < this.positionList.length; i++) {
        const item = this.positionList[i]
        const value = item.value
        if (!value) {
          this.$message.error('请填写完整')
          return
        }
        const existList = this.positionList.filter(item => {
          if (item.value === value) return true
        })
        if (existList.length > 1) {
          this.$message.error(value + '职位重复')
          return
        }
        if (i === this.positionList.length - 1) {
          positions = positions.concat(item.value)
        } else {
          positions = positions.concat(item.value + '、')
        }
      }

      // this.positionList.forEach((item, index) => {
      //   if (index === this.positionList.length - 1) {
      //     positions = positions.concat(item.value)
      //   } else {
      //     positions = positions.concat(item.value + ',')
      //   }
      // })
      this.formData.personPosition = positions

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm()
        } else {
          return false
        }
      })
    },
    submitForm() {
      // 转化薪资
      this.formData.minSalary = this.formData.selectSalary.minSalary
      this.formData.maxSalary = this.formData.selectSalary.maxSalary
      // 转化期望地点
      const cityCode = this.formData.expectCitySelect[1]
      const cityName = this.formatterCity(cityCode)
      this.formData.expectCityTop = this.formatterCity(this.formData.expectCitySelect[0])
      this.formData.expectCity = cityName
      // 转化培训机构
      const selectList = this.formData.organizeSelect
      if (this.formData.organizeSelect && this.formData.organizeSelect.length > 0) {
        this.formData.etcOrganize = this.formData.organizeSelect.reduce((all, item, index) => {
          const match = this.originOrganizeList.find(originItem => {
            if (item === originItem.value) {
              return true
            }
          }) || {}
          if (index === this.formData.organizeSelect.length - 1) {
            return all + match.label
          } else {
            return all + match.label + '/'
          }
        }, '')
        this.formData.organId = selectList[selectList.length - 1]
      }
      // 转化城市
      const areaCode = this.formData.personArcitySelect[1]
      const areaName = this.formatterCity(areaCode)
      this.formData.personArcity = areaName
      // 格式化行业
      let personIndustry = ''
      this.formData.personIndustrySelect.forEach((item, index) => {
        if (index === this.formData.personIndustrySelect.length - 1) {
          personIndustry = personIndustry.concat(item[1])
        } else {
          personIndustry = personIndustry.concat(item[1] + ',')
        }
      })
      this.formData.personIndustry = personIndustry
      this.$post(this.urls.savePerson, this.formData).then(result => {
        if (result.code === 'SUCCESS') {
          if (!this.personId) {
            this.$emit('saveSuccess', result.data)
          } else {
            this.$emit('saveSuccess', this.personId)
          }
          this.objCommitSuccess(this.resume, this.formData)
        } else {
          this.$message.error('保存失败')
        }
      }).catch(() => {
        this.$message.error('保存失败')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/resume";
.edit-container {
  margin-left: px2vw(110);
  margin-right: 0;
}
</style>
<style lang="scss">
.avatar-uploader {
  width: px2vw(150);
  height: px2vw(180);
  position: absolute;
  top: px2vw(100);
  z-index: 2;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: px2vw(30);
  color: #8c939d;
}
.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.el-icon-delete {
  cursor: pointer;
}
.el-icon-delete:hover {
  color: rgb(92, 182, 255);
}
</style>
