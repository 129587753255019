<template>
  <article class="recommend">
    <swiperbanner v-if="postFlag===1" class="banner" :dict-code="Banner.BANNER_JOBPOSITION"></swiperbanner>
    <el-row v-if="postFlag===1" :gutter="10" style="display: flex; align-items: center; justify-content: center">
      <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="16">
        <div>
          <div class="title"><span>岗位推荐</span></div>
          <el-row :gutter="20" style="padding-left:0;padding:right:0">
            <el-col v-for="(item, index) in postRecords" :key="index" :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
              <div class="curpoin" @click="openPostDetail(item,1)">
                <el-card class="card">
                  <div class="line">
                    <div class="flexcenbet fontbold">
                      <div class="postName" :title="item.jobname">
                        {{ item.jobname }}
                      </div>
                      <div class="postPrice">{{ item.salaryPackage }}</div>
                    </div>
                    <div style="height: 32px;">
                      <el-tag v-if="formattingField(dictionary.education,item.education,'dictCode','dictValue')" type="info">{{
                        formattingField(
                          dictionary.education,
                          item.education,
                          "dictCode",
                          "dictValue"
                        )
                      }}</el-tag>
                      <el-tag v-if="item.workYear" type="info">{{
                        item.workYear
                      }}</el-tag>
                    </div>
                  </div>
                  <div class="flexcenbet">
                    <div :title="item.enterName" class="companyA">
                      {{ item.enterName }}
                    </div>
                    <div :title="item.workAddress" class="address">
                      {{ item.workAddress }}
                    </div>
                  </div>
                </el-card>
              </div>
            </el-col>
          </el-row>
          <div class="more" @click="openPostmore">查看更多 ></div>
        </div>
        <!-- <div>
          <div class="title">企业推荐</div>
          <el-row :gutter="20">
            <el-col
              v-for="(item, index) in companyRecords"
              :key="index"
              :xs="12"
              :sm="12"
              :md="8"
              :lg="8"
              :xl="8"
            >
              <div class="curpoin" @click="openCompanyDetail(item,1)">
                <el-card class="card">
                  <div style="width: 100%">
                    <div class="imgDiv">
                      <img :src="getImg(item.enterLogo)" @error="imgError">
                    </div>

                    <div class="conpanyline">
                      <div :title="item.enterName" class="conpanyName">
                        {{ item.enterName }}
                      </div>
                    </div>
                  </div>
                  <div class="flexcenbet financing">
                    <el-tag v-if="item.enterField">
                      {{ getIndustryName(item.enterField) }}
                    </el-tag>
                    <el-tag v-if="item.enterScale" type="info">
                      {{
                        formattingField(
                          financialStageList,
                          item.enterScale,
                          "dictCode",
                          "dictValue"
                        )
                      }}
                    </el-tag>
                  </div>
                  <div class="flexcencen financing">
                    <div class="postNum">岗位数：{{ item.countNumber }}</div>
                    <span class="vline">|</span>
                    <div class="demandNum">需求人数：{{ item.need }}</div>
                  </div>
                </el-card>
              </div>
            </el-col>
          </el-row>
          <div class="more" @click="openCompanymore">查看更多 ></div>
        </div> -->
        <div>
          <div class="title"><span>简历推荐</span></div>
          <section class="text-containerB">
            <ul v-if="tableData.length>0" class="container-ul">
              <li v-for="(item, index) in tableData" :key="index" class="container-li" @click="handleSee(item)">
                <div class="flexcenbet" style="width:100%">
                  <div class="user-info">
                    <div class="portrait">
                      <img :src="getHolderImage(item)">
                    </div>
                    <div class="details">
                      <p>
                        {{ formatName(item.personName) }}
                      </p>
                      <p>
                        {{
                          item.birthdate === null
                            ? "未填写"
                            : calculationDifference(item.birthdate) + "岁"
                        }}
                        <span class="line">|</span>
                        工作{{ item.workdate }}年
                        <span class="line">|</span>
                        {{ formatterData(dictionary.education, item.personEducation) }}
                        <span class="line">|</span>
                        {{ formatterData(dictionary.job_status, item.personState) }}
                      </p>
                      <p>
                        期望：
                        <span v-if="item.minSalary && item.maxSalary" style="color: #fb6e20">{{ formatterSalary(item.minSalary) }} -
                          {{ formatterSalary(item.maxSalary) }}</span>
                        <span v-else style="color: #fb6e20">{{
                          formatterSalary(item.minSalary)
                        }}</span>
                        <span class="line">|</span>
                        <span class="limit" :title="item.expectCity">
                          {{ item.expectCity }}
                        </span>
                        <span class="line">|</span>
                        <span class="limit" :title="item.personPosition">
                          {{ item.personPosition }}
                        </span>
                      </p>
                      <p v-if="item.skillLabels" class="tags">
                        <span v-for="(itemskill, index1) in item.skillLabels.slice(0,4)" :key="index1" :title="itemskill.skillName">{{ itemskill.skillName }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="experience-info">
                    <ul>
                      <div v-if="item.workExperiences">
                        <li v-for="(itemWork, indexWork) in item.workExperiences.slice(0,3)" :key="indexWork">
                          <div class="time">
                            {{ formatDate(itemWork.startTime) }} -
                            {{ formatDate(itemWork.endTime) }}
                          </div>
                          <div class="company" :title="itemWork.workCompany">
                            {{ itemWork.workCompany }}
                          </div>
                          <div class="position" :title="itemWork.workPosition">
                            {{ itemWork.workPosition }}
                          </div>
                        </li>
                      </div>
                      <!-- 因为上方for循环的key已经有重复的key,所以下方需要从4开始进行循环 -->
                      <div v-if="item.educations">
                        <li v-for="(itemEdu, indexEdu) in item.educations.slice(0, 1)" :key="indexEdu+4">
                          <div class="time">
                            {{ formatDate(itemEdu.startTime) }} -
                            {{ formatDate(itemEdu.endTime) }}
                          </div>
                          <div class="company" :title="itemEdu.schoolName">
                            {{ itemEdu.schoolName }}
                          </div>
                          <div class="position" :title="itemEdu.schoolMajor">
                            {{ itemEdu.schoolMajor }}
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            <el-empty v-else description="暂无简历"></el-empty>
          </section>
          <div class="more" @click="openResumemore">查看更多 ></div>
        </div>
      </el-col>
    </el-row>
    <detail v-if="postFlag===2" :id="postId" :source-num="sourceNum" :source-num-list="sourceNumList" @close="pagefun"></detail>
    <postmore v-if="postFlag===3" :source-num-list="sourceNumList" :source-num="sourceNum" @close="pagefun" @openDetail="openPostDetail"></postmore>
    <edit-resume v-if="postFlag===7" :origin-person-id="personId" :handle-enable="false" :show-match="true" :source-num-list="sourceNumList" @close="pagefun" @handleBack="postFlag=1"></edit-resume>
    <resumemore v-if="postFlag===6" :source-num-list="sourceNumList" @close="pagefun" @openeditresume="openeditresume"></resumemore>
    <!-- <company-detail v-if="postFlag===4" :id="enterId" :source-num-list="sourceNumList" :source-num="sourceNum" @openDetail="companyOpenPostDetail" @close="pagefun"></company-detail>
    <companymore v-if="postFlag===5" :source-num="sourceNum" :source-num-list="sourceNumList" @close="pagefun" @openDetail="openCompanyDetail"></companymore> -->
  </article>
</template>

<script>
import backgroundImage from '../../../../assets/images/company.png'
import detail from './components/detail.vue'
// import companyDetail from './components/companyDetail.vue'
import Postmore from './components/postmore.vue'
// import companymore from './components/companymore.vue'
import Swiperbanner from '../../../../components/swiperbanner.vue'
import { Banner } from '../../../../utils/dict'
import dictMixin from '../wisdom/mixins/dictMixin'
import boyHolder from '../../../../assets/images/boy.jpg'
import girlHolder from '../../../../assets/images/girl.jpg'
import editResume from './editResume.vue'
import resumemore from './components/resumemore.vue'

export default {
  components: { Swiperbanner, detail, Postmore, editResume, resumemore },
  mixins: [dictMixin],
  data() {
    return {
      Banner,
      searchForm: {},
      postRecords: [],
      companyRecords: [],
      dictionary: {},
      financialStageList: [],
      industryList: [],
      postId: null,
      postFlag: 1,
      sourceNum: 1,
      enterId: null,
      sourceNumList: [1],
      tableData: [],
      personId: null
    }
  },
  mounted() {
    document.body.scrollTop = 0
    this.requestData()
    this.getdictionary()
    // this.companyRequestData()
    this.getresumeData()

    if (this.$route.query && this.$route.query.postId && this.$route.query.pageFlag) {
      this.postId = Number(this.$route.query.postId)
      this.postFlag = Number(this.$route.query.pageFlag)
      console.log(this.postFlag, this.postId)
    }
  },
  methods: {
    formatName(str) {
      return str.substring(0, 1) + new Array(str.length).join('*')
    },
    getHolderImage(val) {
      const sex = val.personSex
      if (sex === '0') {
        return girlHolder
      } else {
        return boyHolder
      }
    },
    // 请求列表数据
    getresumeData() {
      this.toggleLoading(true)
      this.$get(this.urls.enterSettledList, this.searchForm, [1, 5]).then((result) => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          const data = result.data
          this.tableData = data.records
          this.calculateWorking(this.tableData)
        } else {
          this.$message.error(result.msg)
        }
      })
        .catch(() => {
        })
    },
    formatDate(time) {
      if (time !== null) {
        var date = new Date(time)
        var YY = date.getFullYear() + '/'
        var MM =
          date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1
        return YY + MM
      } else {
        return '至今'
      }
    },
    calculateWorking(data) {
      data.forEach(element => {
        const now = new Date().getTime()
        if (element.workdate) {
          const workMilli = now - new Date(element.workdate).getTime()
          const workExperience = Math.floor(workMilli / (365 * 24 * 3600 * 1000)) + 1
          element.workdate = workExperience
        } else if (element.educations.length > 0) {
          if (new Date(element.educations[0].endTime).getTime()) {
            const workMilli = now - new Date(element.educations[0].endTime).getTime()
            const workExperience = Math.floor(workMilli / (365 * 24 * 3600 * 1000)) + 1
            element.workdate = workExperience
          }
        } else {
          element.workdate = '0'
        }
      })
    },
    pagefun(num) {
      this.postFlag = num
      if (num === 3 || num === 5) {
        this.sourceNumList = [1, num]
      }
      if (num === 4 && this.sourceNumList[1] === 5) {
        this.sourceNumList = [1, 5, 4]
      }

      if (num === 4 && this.sourceNumList[1] === 4) {
        this.sourceNumList = [1, 4]
      }
      if (num === 1) {
        this.sourceNumList = [1]
      }
    },
    openPostmore() {
      this.postFlag = 3
      this.sourceNumList = [1, 3]
    },
    // openCompanymore() {
    //   this.postFlag = 5
    //   this.sourceNumList = [1, 5]
    // },
    openResumemore() {
      this.postFlag = 6
      this.sourceNumList = [1, 6]
    },
    // 打开简历详情
    openeditresume(item, num) {
      this.personId = item.personId
      this.sourceNum = num
      this.postFlag = 7
      this.sourceNumList = [1, 6, 7]
    },
    // // 打开岗位详情页面
    openPostDetail(item, num) {
      this.postId = item.sid
      // 1是主页面，2是岗位详情页面，3是更多岗位列表，4是公司详情，5是公司列表
      this.sourceNum = num
      this.postFlag = 2
      if (this.sourceNumList[1] === 3 && this.sourceNum !== 3) {
        this.sourceNumList = [1, 3, 2]
      } else if (this.sourceNumList[1] === 5 && this.sourceNum !== 5) {
        this.sourceNumList = [1, 5, 4, 2]
      } else {
        this.sourceNumList = [1, 2]
      }
    },
    // // 由公司列表打开岗位详情
    // companyOpenPostDetail(item, num) {
    //   this.postId = item.sid
    //   this.sourceNum = num
    //   this.postFlag = 2
    //   if (this.sourceNumList[1] === 4 && this.sourceNum !== 4) {
    //     this.sourceNumList = [1, 4, 2]
    //   } else {
    //     this.sourceNumList = [1, 5, 4, 2]
    //   }
    // },
    // openCompanyDetail(item, num) {
    //   this.enterId = item.enterId
    //   this.sourceNum = num
    //   this.postFlag = 4
    //   if (this.sourceNumList[1] === 5 && this.sourceNum !== 5) {
    //     this.sourceNumList = [1, 5, 4]
    //   } else {
    //     this.sourceNumList = [1, 4]
    //   }
    // },
    // 加默认封面
    getImg(item) {
      if (item) return item
      return backgroundImage
    },
    // 图片链接失效/错误给默认图
    imgError(event) {
      const img = event.srcElement
      img.src = backgroundImage
      img.onerror = null // 防止闪图
    },
    getIndustryName(val) {
      if (!val || val.length === 0) return ''
      const firstIndex = val.split(',')[0]
      return this.formattingField(
        this.industryList,
        firstIndex,
        'dictCode',
        'dictValue'
      )
    },
    // 获取字典数据
    getdictionary() {
      this.$get(`${this.urls.dicValues}`, {
        types: 'education,enter_industry,enterprise_scale,job_status'
      })
        .then((result) => {
          if (result.code === 'SUCCESS') {
            this.dictionary = result.data
            this.industryList = this.dictionary.enter_industry
            this.financialStageList = this.dictionary.enterprise_scale
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch(() => {
          this.toggleLoading(false)
        })
    },
    // 岗位列表获取
    requestData() {
      this.$get(this.urls.postRecommendList, this.searchForm, [1, 9])
        .then((result) => {
          if (result.code === 'SUCCESS') {
            const data = result.data
            this.postRecords = data.records
            this.total = data.total
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch((err) => {
          console.log('err==', err)
        })
    },
    // // 企业列表获取
    // companyRequestData() {
    //   this.$get(this.urls.companyRecommendList, this.searchForm, [1, 9])
    //     .then((result) => {
    //       if (result.code === 'SUCCESS') {
    //         const data = result.data
    //         this.companyRecords = data.records
    //         this.total = data.total
    //       } else {
    //         this.$message.error(result.msg)
    //       }
    //     })
    //     .catch((err) => {
    //       console.log('err==', err)
    //     })
    // },
    // 给定一个时间计算出与当前时间相差年份
    calculationDifference(dateTime) {
      if (dateTime !== undefined && dateTime != null) {
        const birthdays = new Date(dateTime.replace(/-/g, '/'))
        const d = new Date()
        const age =
          d.getFullYear() -
          birthdays.getFullYear() -
          (d.getMonth() < birthdays.getMonth() ||
            (d.getMonth() === birthdays.getMonth() &&
              d.getDate() < birthdays.getDate())
            ? 1
            : 0)
        return age
      } else {
        return 0
      }
    },
    handleSee(row) {
      this.personId = row.personId
      this.postFlag = 7
      this.sourceNumList = [1, 7]
    }
  }
}
</script>

<style lang="scss" scoped>
.recommend {
  width: 100%;
}
.title {
  // font-size: 32px;
  // font-weight: 900;
  // width: 100%;
  margin: 30px 0;
  width: 100%;
  line-height: 46px;
  font-size: 24px;
  font-weight: inherit;
  color: #252b3a;
  font-family: Hiragino Sans GB, Helvetica Neue, Helvetica, STHeiTi,
    Microsoft Yahei, sans-serif;
}
.more {
  margin: 15px;
  font-size: 16px;
  line-height: 40px;
  color: #575d6c;
  text-align: center;
  cursor: pointer;
  width: 100%;
}
.line {
  padding: 0 0 19px 0;
  border-bottom: 1px solid #ddd;
  min-height: 70px;
}
.conpanyline {
  display: inline-block;
  vertical-align: top;
  width: 76%;
}
.postName {
  height: 28px;
  line-height: 28px;
  color: #252b3a;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 60%;
}
.postPrice {
  color: #c7000b;
  text-align: right;
}
.companyA {
  max-width: 40%;
  margin-top: 20px;
  font-size: 14px;
  color: #333;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.address {
  max-width: 40%;
  margin-top: 20px;
  font-size: 14px;
  color: #333;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-tag.el-tag--info {
  margin-right: 10px;
}
.card {
  margin-bottom: 20px;
}
.flexcenbet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.imgDiv {
  width: 80px;
  height: 60px;
  display: inline-block;
  img {
    width: 100%;
    height: 100%;
  }
}
.conpanyName {
  margin: 13px 0 0 22px;
  font-size: 16px;
  font-weight: 700;
  color: #252b3a;
  letter-spacing: 0;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.financing {
  margin: 20px 0 0 0;
  font-size: 12px;
  color: #999;
}
.flexcencen {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vline {
  margin: 0 30px;
}
.enterField {
  color: #504848;
  font-size: 14px;
}
.fontbold {
  font-weight: 900;
}
.curpoin {
  cursor: pointer;
}
.banner {
  width: 100%;
  height: 430px;
}
.container-ul .container-li:hover {
  transition: all 0.2s ease;
  transform: translateY(-3%);
  // box-shadow: 0 12px 20px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 12px 9px 0 rgb(0 0 0 / 20%);
  z-index: 1;
}
//   .container-ul .container-li:nth-child(odd) {
// background-color: #f7f7f7;
// }
// .container-ul .container-li:nth-child(even) {
// background-color: #fff;
// }
.text-containerB {
  .container-ul {
    .container-li {
      cursor: pointer;
      width: 100%;
      // margin-bottom: 10px;
      min-height: 154px;
      // background: #ffffff;
      // box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
  }
}
.user-info {
  // width: 33%;
  height: 100%;
  display: flex;
  // align-items: center;
  width: 50%;
  // min-width: 470px ;
  .portrait {
    height: 100%;
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin: 24px 20px 0 24px;
    }
  }
  .details {
    // width: 100%;
    height: 100%;
    // padding-top: 24px;
    color: #57606a;
    min-width: 346px;
    P {
      margin-top: 14px;
      .line {
        margin: 0 8px;
        color: #dcdfe6;
      }
      .tags-status {
        border-radius: 2px;
        margin: 2px 4px;
        border: 1px solid #0dd7bb;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #0dd7bb;
      }
    }
    .tags {
      span {
        padding: 0 8px;
        margin-right: 4px;
        display: inline-block;
        height: 24px;
        background: #f1f4f9;
        font-size: 12px;
        border-radius: 12px;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 70px;
      }
    }
  }
}
.experience-info {
  // flex: 1;
  // margin-left: 160px;
  width: 50%;
  ul {
    position: relative;
    li {
      display: flex;
      color: #57606a;
      margin-bottom: 14px;
      padding: 1em 0 0 0;
      font-size: 14px;
      justify-content: space-between;
      align-items: center;
      .time {
        width: 150px;
        margin-right: 24px;
      }
      .company {
        width: 135px;
        margin-right: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .position {
        flex: 1;
        width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    li:before {
      position: absolute;
      margin-top: 3px;
      left: -28px;
      z-index: 2;
      content: " ";
    }
    li:after {
      position: absolute;
      top: 30px;
      z-index: 1;
      left: -23px;
      bottom: 7px;
      content: " ";
      z-index: 1;
      border-right: 2px solid #e2edff;
    }
    li:not(:first-child) {
      padding-top: 8px;
    }
    li:last-child {
      li:after {
        display: none;
      }
    }
    // 设置图标
    li:first-child::before {
      background-image: url("../../../../assets/images/resume-start.png");
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      height: 13px;
      width: 13px;
      border-radius: 50%;
    }
    li:last-child::before {
      background-image: url("../../../../assets/images/resume-end.png");
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      height: 13px;
      width: 13px;
      border-radius: 50%;
    }
    li.is-done {
      li:after {
        border-color: #0091fa;
      }
      li:before {
        background-color: #1874ff;
        box-shadow: #1874ff 0 0 10px;
      }
    }
  }
}
//   .container-ul .container-li:nth-child(odd) {
// background-color: #f7f7f7;
// }
// .container-ul .container-li:nth-child(even) {
// background-color: #fff;
// }
.limit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 230px;
}
.el-row {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.container-li {
  background-color: #fff;
  box-shadow: 0 0px 5px 0 rgb(0 0 0 / 10%);
  margin-top: 10px;
}

.title span {
  position: relative;
}

.title span:after {
  position: absolute;
  content: " ";
  left: -16px;
  top: 50%;
  margin-top: -10px;
  width: 4px;
  height: 20px;
  background: linear-gradient(135deg, #2791ff 0, #03a7ff 98%);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
</style>
