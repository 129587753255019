<!--
   User: Liu Yin
   Date: 2020/3/23
   Description:公司列表
 -->
<template>
  <div>
    <article ref="list">
      <ul class="post-container">
        <li v-for="(item,index) in tableData" :key="index" class="post-item-parent" @click="openDetail(item,sourceNum)">
          <el-card :body-style="{ padding: '0px' }" class="card" shadow="hover">
            <div class="post-item">
              <article class="post-info">
                <section class="post-left" style="width:50%">
                  <span class="post-title" style="display: inline-block;overflow: hidden;width: 160px;text-overflow: ellipsis;white-space: nowrap">{{ item.jobname }} [{{ item.workAddress }}]</span>
                  <span class="job-pub-time">发布于{{ item.pubdate | moment('YYYY-MM-DD') }}</span>
                  <div class="left-bottom">
                    <span class="post-salary">{{ item.salaryPackage }}</span>
                    <p class="introduce">{{ item.workYear }} |
                      <span v-if="item.education">{{ formattingField(educationList,item.education,'dictCode','dictValue') }} |</span>
                      招聘人数: {{ item.demand }}人</p>
                  </div>
                </section>
                <section class="post-right">
                  <img class="company-logo" :src="getLogo(item)">
                  <div style="display: inline-block;max-width:75%">
                    <span class="post-title" style="display: inline-block;max-width: 100%;">{{ item.enterName }}</span>
                    <div style="display: flex;align-items: center;margin-top: 15px">
                      <p class="introduce" style="font-size: 13px">
                        <span style="max-width: 85px;display: inline-block;" class="single-line">{{ getIndustryName(item.enterField) }}</span>
                        <span v-if="item.enterField"> |</span>
                        {{ formattingField(enterpriseScaleList,item.enterScale,'dictCode','dictValue') }}
                        <!-- <span v-if="item.enterScale"> |</span>
                        {{ formattingField(financialStageList,item.enterFinance,'dictCode','dictValue') }} -->
                      </p>
                    </div>
                  </div>
                </section>
              </article>
              <ul class="post-label">
                <li v-for="(labelItem,labelIndex) in item.skillList" :key="labelIndex">
                  {{ labelItem }}
                </li>
              </ul>
            </div>
          </el-card>
        </li>
      </ul>

      <el-pagination
        class="pageAction"
        :current-page="currentPage"
        :page-size="size"
        :background="false"
        layout="prev, pager, next, jumper"
        :total="total"
        style="text-align: center;"
        @current-change="handleCurrentChanges"
      />
    </article>
  </div>
</template>

<script>
import enterLogo from '../../../../../assets/images/company.png'
import pageMixin from '../../../../../utils/mixins/pageMixin'
import listMixin from '../../../../../utils/mixins/listMixin'
// import { List, PullRefresh } from 'vant'
export default {
  components: {
    // VanList: List,
    // VanPullRefresh: PullRefresh
  },
  mixins: [pageMixin, listMixin],
  props: {
    dictObj: {
      type: Object,
      default() {
        return {}
      }
    },
    searchObj: {
      type: Object,
      default() {
        return {}
      }
    },
    sourceNum: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      industryList: this.dictObj.enter_industry,
      financialStageList: this.dictObj.financing_scale,
      enterpriseScaleList: this.dictObj.enterprise_scale,
      enterTypeList: this.dictObj.enter_type,
      educationList: this.dictObj.education,
      entcode: '',
      tableData: []
    }
  },
  watch: {
    dictObj(val) {
      this.industryList = this.dictObj.enter_industry
      this.financialStageList = this.dictObj.financing_scale
      this.enterpriseScaleList = this.dictObj.enterprise_scale
      this.enterTypeList = this.dictObj.enter_type
      this.educationList = this.dictObj.education
    }
  },
  mounted() {
    this.searchForm = this.searchObj
    this.requestData()
  },
  methods: {
    getLogo(row) {
      return row.enterLogo ? row.enterLogo : enterLogo
    },
    getIndustryName(val) {
      if (val) {
        const firstIndex = val.split(',')[0]
        return this.formattingField(this.industryList, firstIndex, 'dictCode', 'dictValue')
      }
    },
    getSalary(item) {
      if (item) {
        const minSalary = item.minSalary / 1000
        const maxSalary = item.maxSalary / 1000
        return `${minSalary}-${maxSalary}K`
      }
    },
    // 请求列表数据
    requestData() {
      this.toggleLoading(true)
      this.$get(this.urls.postRecommendList, this.searchForm, [this.currentPage, this.size]).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          const list = result.data.records
          list.forEach(item => {
            if (item.stationSkill) {
              const skill = item.stationSkill
              if (skill) {
                item.skillList = skill.split(';')
              }
            }
          })
          this.tableData = list

          this.total = result.data.total
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    },
    openDetail(item, num) {
      this.$emit('openDetail', item, 2)
      // this.postId = item.sid
      // this.postFlag = 2
    }
  }
}
</script>

<style lang="scss" scoped>
    .post-container{
      display: flex;
      flex-wrap: wrap;
      .post-item-parent{
        width: 50%;
        box-sizing: border-box;
        .card{
          position: relative;
          margin: 0 7px 15px 7px;
          .post-item{
            padding: 20px;
            .left-bottom{
              display: flex;align-items: center;margin-top: 15px
            }
          }
          .post-item:hover{
            background-color: #D8E0FA;
          }
          .post-label{
            height: 20px;
          }
        }
      }
    }

  .post-title{
    font-size: 15px;
    color: #00c2b3;
    line-height: 26px;
    font-weight: 400;
    overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  }
  .introduce{
    font-size: 14px;
    color: #788099;
  }
  .vline {
    display: inline-block;
    width: 1px;
    height: 12px;
    vertical-align: middle;
    background: #e0e0e0;
    margin: 0 10px;
  }
  .post-item{
    background: white;
    cursor: pointer;
    transition: all 0.4s;
    .post-salary{
      flex-shrink: 0;
      height: 26px;
      display: inline-block;
      line-height: 26px;
      margin-right: 10px;
      position: relative;
      vertical-align: middle;
      color: #fc703e
    }
    .post-info{
      display: flex;
      align-items: center;

      .post-logo{
        width: 55px;
        height: 55px;
        border-radius: 10px;
        border: solid 1px #f2f5fa;
      }
      .post-left{
        flex-shrink: 0;
        width: 50%;
        .job-pub-time{
          font-size: 13px;
          color: #8d92a1;
          overflow: hidden;
          text-overflow: ellipsis;
        }

      }
      .post-right{
        display: flex;
        align-items: center;
        margin-left: 30px;
        width: 50%;
        .company-logo{
          width: 50px;
          height: 50px;
          border-radius: 10px;
          margin-right: 15px;
        }
      }
    }
    .post-label{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li{
        padding: 3px 5px;
        border: #b6bcd0 solid 1px;
        border-radius: 3px;
        margin-right: 15px;
        margin-top: 10px;
        font-size: 12px;
        font-weight: 300;
        color: #858a98;
        font-family: -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
      }
    }
  }
.el-row{
  padding-left:0;
  padding-right:0;
  margin-left:0 !important;
  margin-right:0 !important;
}
</style>
