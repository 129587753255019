import { render, staticRenderFns } from "./projectInfo.vue?vue&type=template&id=aebb3e12&scoped=true&"
import script from "./projectInfo.vue?vue&type=script&lang=js&"
export * from "./projectInfo.vue?vue&type=script&lang=js&"
import style0 from "./projectInfo.vue?vue&type=style&index=0&id=aebb3e12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aebb3e12",
  null
  
)

export default component.exports