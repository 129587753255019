/**
 * @author Liu Yin
 * @date 2020/2/11
 * @Description: 列表页面混入
 */
export default {
  data() {
    return {
      // 搜索条件
      searchForm: {}
    }
  },
  methods: {
    // 执行搜索
    handleSearch() {
      this.$refs.list.handleSearch(this.searchForm)
    },
    resetSearchForm() {
      this.searchForm = {}
      this.handleSearch()
    },
    requestData() {
      this.$refs.list.requestData(this.searchForm)
    },
    // Array转化成Parent->children
    converseData(originList) {
      if (!originList) return []
      const list = JSON.parse(JSON.stringify(originList))
      const result = []
      const map = {}
      list.forEach(item => {
        item.label = item.dictValue
        item.text = item.dictValue
        item.value = item.dictCode
        item.id = item.dictCode
        map[item.dictId] = item
      })
      list.forEach(item => {
        const parent = map[item.parentId]
        if (parent) {
          (parent.children || (parent.children = [])).push(item)
        } else {
          result.push(item)
        }
      })
      return result
    }
  }
}
