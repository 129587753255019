
<template>
  <!--个人信息-->
  <article class="preview-module">
    <section>
      <el-upload class="avatar-uploader" :action="uploadUrl" accept="image/*" :show-file-list="false" :on-success="handleAvatarSuccess" :on-error="handleUploadError" :before-upload="beforeAvatarUpload">
        <img :src="formData.personPicture?formData.personPicture:headHolder" class="avatar">
      </el-upload>
      <el-form ref="form" class="edit-container" label-width="120px" :model="formData" :rules="formRules" label-position="right">
        <el-row>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="姓名：" prop="personName" class="name-line">
              <el-input id="username" v-model="formData.personName" placeholder="请输入姓名" maxlength="32" />
            </el-form-item>

          </el-col>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="联系方式：" prop="personMobile">
              <el-input v-model="formData.personMobile" placeholder="请输入联系方式" maxlength="11" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="个人邮箱：" prop="personMail">
              <el-input v-model="formData.personMail" placeholder="请输入个人邮箱" maxlength="32" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="户籍：" prop="personArcitySelect">
              <el-cascader v-model="formData.personArcitySelect" :options="cityOptions" :props="{ expandTrigger: 'hover' }" :show-all-levels="false"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="出生日期：" prop="birthdate">
              <el-date-picker v-model="formData.birthdate" type="year" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="pickerOptions" placeholder="请选择出生日期" />
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="性别：" prop="personSex">
              <el-radio-group v-model="formData.personSex">
                <el-radio label="1">男</el-radio>
                <el-radio label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="学历：" prop="personEducation">
              <el-select v-model="formData.personEducation" placeholder="请选择学历" value="" value-key="dictCode">
                <el-option v-for="item in educationList" :key="item.dictCode" :label="item.dictValue" :value="item.dictCode" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="学校：" prop="schoolName">
              <el-input v-model="formData.schoolName" placeholder="请输入学校" maxlength="32" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="专业方向：" prop="personMajor">
              <el-input v-model="formData.personMajor" placeholder="请输入专业方向" maxlength="32" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="学校类型：" prop="collegeType">
              <div style="display: flex">
                <el-select v-model="formData.collegeType" placeholder="请选择学校类型" value="" value-key="dictCode">
                  <el-option v-for="(item,index) in collegeType" :key="index" :label="item.dictValue" :value="item.dictCode" />
                </el-select>
                <el-checkbox v-model="isRecruitChecked" style="margin-left: 10px" @change="changesCheckBox($event,1)">统招</el-checkbox>
              </div>
            </el-form-item>

          </el-col>

          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="外语：" prop="personLanguages">
              <el-input v-model="formData.personLanguages" placeholder="请输入外语" maxlength="32" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="开始工作：" prop="workdate">
              <el-date-picker v-model="formData.workdate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="开始工作时间" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="海外经历：">
              <el-checkbox v-model="abroadWorkChecked" @change="changesCheckBox($event,2)">工作</el-checkbox>
              <el-checkbox v-model="abroadStudyChecked" @change="changesCheckBox($event,3)">留学</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="工作性质：" prop="personWork">
              <el-select v-model="formData.personWork" placeholder="请选择工作性质" value="" value-key="dictCode">
                <el-option v-for="item in workNature" :key="item.dictCode" :label="item.dictValue" :value="item.dictCode" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="求职状态：" prop="personState">
              <el-select v-model="formData.personState" placeholder="请选择求职状态" value="">
                <el-option v-for="item in jobStatusList" :key="item.dictCode" :label="item.dictValue" :value="item.dictCode" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="期望行业：" prop="personIndustrySelect">
              <el-cascader v-model="formData.personIndustrySelect" :options="industryOptions" :props="{ expandTrigger: 'hover',multiple:true }" :show-all-levels="false" mutiple placeholder="请输入期望行业" @change="handleChangeIndustry"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="期望薪资：" prop="selectSalary">
              <el-select v-model="formData.selectSalary" placeholder="请选择期望薪资" value="" value-key="name">
                <el-option v-for="item in salaryList" :key="item.name" :label="item.name" :value="item" />
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :xs="24" :sm="12" :lg="8">
            <el-form-item label="期望城市：" prop="expectCitySelect">
              <el-cascader v-model="formData.expectCitySelect" :options="cityOptions" :props="{ expandTrigger: 'hover' }" :show-all-levels="false"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :lg="24">
            <el-form-item label="期望职位：" prop="personPosition">
              <ul style="display: flex;flex-wrap: wrap;height: 40px;">
                <li v-for="(item,index) in positionList" :key="index" class="position-item">
                  <el-input :id="'position'+index" v-model="item.value" placeholder="请输入期望职位" maxlength="32">
                    <i slot="suffix" class="el-input__icon el-icon-delete" @click="removePosition(index)"></i>
                  </el-input>
                </li>
                <el-button type="info" size="mini" class="el-icon-plus" @click="addPosition">添加职位</el-button>
              </ul>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="edit-footer" style="display: flex;align-items: center;justify-content: center;margin: 0 auto;">
          <el-button @click="closeEdit">
            返回
          </el-button>
          <el-button type="primary" class="confirm" @click="validateForm">
            投递在线简历
          </el-button>
          <el-upload ref="fileRefs" class="uploadbutton" :action="importResumeUrl" :show-file-list="false" :on-success="handleUploadSuccess" :on-error="handleUploadError" :before-upload="beforeUpload" :data="uploadParams">
            <el-tooltip class="item" effect="dark" content="仅支持上传word或pdf文件" placement="top">
              <el-button id="initSlide" type="primary" @click.prevent.stop="setResumeNameSuccess">
                投递附件简历
              </el-button>
            </el-tooltip>

          </el-upload>
          <el-button type="primary" @click.prevent.stop="exportResume()">
            导出模板
          </el-button>
        </div>
      </el-form>
    </section>
  </article>
</template>

<script>
import dictMixin from '../../wisdom/mixins/dictMixin'
import talentMixin from '../../wisdom/mixins/talentMixin'
// import resumeEditMixin from '../../wisdom/mixins/resumeEditMixin'
import { isPhone, validEmail } from '../../../../../utils/validate'
import headHolder from '../../../../../assets/images/head-holder.jpg'
import pageMixin from '../../../../../utils/mixins/pageMixin'

export default {
  mixins: [dictMixin, talentMixin, pageMixin],

  props: {
    dictObj: {
      type: Object,
      default: function () {
        return {}
      }
    },
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          // 此条为设置禁止用户选择今天之前的日期，包含今天。
          // return time.getTime() <= (Date.now()-(24 * 60 * 60 * 1000));
          // 此条为设置禁止用户选择今天之前的日期，不包含今天。
          return time.getTime() >= (Date.now())
        }
      },
      cityOptions: this.converseData(this.dictObj.area),
      industryOptions: this.converseData(this.dictObj.enter_industry),
      headHolder: headHolder,
      formData: {
        schoolName: '',
        // 姓名
        personName: '',
        // 电话
        personMobile: '',
        // 期望城市
        expectCity: '',
        // 选择的期望城市
        expectCitySelect: [],
        // 籍贯
        personArcity: '',
        // 选择的籍贯城市
        personArcitySelect: [],
        // 性别
        personSex: '1',
        // 出生日期
        birthdate: '',
        // 开始工作时间
        workdate: '',
        // 个人邮箱
        personMail: '',
        // 求职状态
        personState: '',
        // 期望行业
        personIndustry: '',
        // 选中的期望行业
        personIndustrySelect: [],
        // 期望职位
        personPosition: '占位',
        // 工作性质
        personWork: '',
        // 选择的薪资
        selectSalary: null,
        // 学历
        personEducation: '',
        // 头像
        personPicture: '',
        // 专业
        personMajor: null,
        // 学校类型
        collegeType: '',
        // 是否统招  0非统招  1统招
        isRecruit: 0,
        // 是否海外工作  0非  1
        abroadWork: 0,
        // 是否海外留学  0非  1
        abroadStudy: 0,
        // 外语
        personLanguages: ''
      },
      positionList: [],
      uploadUrl: this.urls.BASE_URL + this.urls.uploadFiles,
      importResumeUrl: this.urls.BASE_URL + '/portal/submitEnc',
      formRules:
      {
        personName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        personMobile: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (isPhone(value)) {
                callback()
              } else {
                callback(new Error('请输入正确的联系方式'))
              }
            }, trigger: 'blur'
          }
        ],
        expectCitySelect: [
          { required: true, message: '请选择期望城市', trigger: 'blur' }
        ],
        personArcitySelect: [
          { required: true, message: '请选择户籍', trigger: 'blur' }
        ],
        personSex: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        birthdate: [
          { required: true, message: '请选择出生日期', trigger: 'blur' }
        ],
        personMail: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (validEmail(value)) {
                callback()
              } else {
                callback(new Error('请输入正确的邮箱'))
              }
            }, trigger: 'blur'
          }
        ],
        personState: [
          { required: true, message: '请输入求职状态', trigger: 'blur' }
        ],
        personIndustrySelect: [
          { required: true, message: '请选择行业', trigger: 'blur' }
        ],
        personPosition: [
          { required: true, message: '请输入期望职位', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const empty = this.positionList.some(item => {
                if (!item.value) {
                  return true
                }
              })
              if (empty) {
                callback(new Error('请输入期望职位'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        personWork: [
          { required: true, message: '请选择工作性质', trigger: 'blur' }
        ],
        selectSalary: [
          { required: true, message: '请选择期望薪资', trigger: 'blur' }
        ],
        personEducation: [
          { required: true, message: '请选择学历', trigger: 'blur' }
        ]
      },
      // 选择是否统招
      isRecruitChecked: false,
      // 选择是否海外经历
      abroadWorkChecked: false,
      // 选择是否海外留学
      abroadStudyChecked: false,
      jobStatusList: this.dictObj.job_status,
      workNature: this.dictObj.work_nature,
      educationList: this.dictObj.education,
      collegeType: this.dictObj.college_type,
      uploadParams: {
        sid: this.id
      }
    }
  },
  mounted() {
  },
  methods: {

    exportResume() {
      this.downloadFile('https://www.rencai-sz.com/group1/M00/00/0D/wKgAG2N7MQmATD9WAAA9DWP3EyA62.docx', '简历模板.docx')
    },
    closeEdit() {
      this.$emit('close')
    },
    setResumeNameSuccess() {
      const file = this.$refs['fileRefs']
      const inner = file.$refs['upload-inner']
      inner.handleClick()
    },
    handleUploadSuccess(res, file) {
      if (res.code === 'SUCCESS') {
        this.$message.success(res.msg)
        this.$emit('close')
      } else {
        this.$message.error(res.msg)
      }
      this.toggleLoading(false)
    },
    beforeUpload(file) {
      const name = file.name
      const acceptType = ['doc', 'docx', 'pdf']
      const acceptEnable = acceptType.some(item => {
        if (name.indexOf(item) > -1) {
          return true
        }
      })
      if (!acceptEnable) {
        this.$message.error('简历附件类型只能为word或pdf')
        return false
      }
      const limitMaxSize = file.size / 1024 / 1024 < 20
      if (!limitMaxSize) {
        this.$message.error('文件大小不能超过20M')
      }
      const canUpload = limitMaxSize
      if (canUpload) this.toggleLoading(true)
      return canUpload
    },
    changesCheckBox(b, type) {
      const value = b ? 1 : 0
      switch (type) {
        case 1:
          this.formData.isRecruit = value
          break
        case 2:
          this.formData.abroadWork = value
          break
        case 3:
          this.formData.abroadStudy = value
          break
      }
    },
    handleAvatarSuccess(res, file) {
      this.toggleLoading(false)
      this.formData.personPicture = res.data.filePath
    },
    handleUploadError(res) {
      const r = JSON.parse(res.message)
      this.$message.error(r.message || r.msg)
      this.toggleLoading(false)
    },
    beforeAvatarUpload(file) {
      const isJPG = (file.type).indexOf('image/') !== -1
      const maxSize = file.size / 1024 / 1024 < 300

      if (!isJPG) {
        this.$message.error('只能上传图片!')
      }
      if (!maxSize) {
        this.$message.error('上传头像图片大小不能超过 300MB!')
      }
      const canUpload = isJPG && maxSize
      if (canUpload) this.toggleLoading(true)
      return canUpload
    },
    handleChangeIndustry(val) {
      const industrySelect = []
      if (val.length > 0) {
        val.forEach(item => {
          industrySelect.push(item[0])
        })
      }
      const match = industrySelect.filter(item => {
        if (industrySelect[0] !== item) {
          return item
        }
      })
      let filteredArr = []
      let indexes = []
      filteredArr = industrySelect.filter(x => industrySelect[0] === x)
      indexes = filteredArr.map(x => industrySelect.indexOf(x))
      if (indexes.length > 0 && match.length > 0) {
        this.$message.warning('一级菜单为单选,请确认选项是否正确')
        this.formData.personIndustrySelect = this.formData.personIndustrySelect.splice(0, indexes.length)
      }
      if (this.formData.personIndustrySelect.length > 3) {
        this.$message.warning('最多选择3个行业')
        this.formData.personIndustrySelect = this.formData.personIndustrySelect.splice(0, 3)
      }
      this.$forceUpdate()
      // if (this.formData.personIndustrySelect.length > 3) {
      //   this.$message.warning('最多选择3个行业')
      //   this.formData.personIndustrySelect = this.formData.personIndustrySelect.splice(0, 3)
      // }
    },
    addPosition() {
      if (this.positionList.length > 0 && this.positionList[this.positionList.length - 1].value === '') {
        return
      }
      if (this.positionList.length >= 6) {
        this.$message.warning('最多添加6个职位')
        return
      }
      this.positionList.push({ value: '' })
      this.$nextTick(() => {
        const dom = document.getElementById('position' + (this.positionList.length - 1))
        dom.focus()
      })
    },
    removePosition(index) {
      this.positionList.splice(index, 1)
    },
    // 提交数据前校验表单
    validateForm() {
      // 格式化职位
      let positions = ''
      this.positionList.forEach((item, index) => {
        if (index === this.positionList.length - 1) {
          positions = positions.concat(item.value)
        } else {
          positions = positions.concat(item.value + ',')
        }
      })
      this.formData.personPosition = positions

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm()
        } else {
          return false
        }
      })
    },
    submitForm() {
      // 转化薪资
      this.formData.minSalary = this.formData.selectSalary.minSalary
      this.formData.maxSalary = this.formData.selectSalary.maxSalary
      // 转化期望地点
      const cityCode = this.formData.expectCitySelect[1]
      const cityName = this.formatterCity(cityCode)
      this.formData.expectCity = cityName

      // 转化城市
      const areaCode = this.formData.personArcitySelect[1]
      const areaName = this.formatterCity(areaCode)
      this.formData.personArcity = areaName
      // 格式化行业
      let personIndustry = ''
      this.formData.personIndustrySelect.forEach((item, index) => {
        if (index === this.formData.personIndustrySelect.length - 1) {
          personIndustry = personIndustry.concat(item[1])
        } else {
          personIndustry = personIndustry.concat(item[1] + ',')
        }
      })
      this.formData.personIndustry = personIndustry
      this.formData.sid = this.id
      this.$post(this.urls.resumeSubmitOnline, this.formData).then(result => {
        if (result.code === 'SUCCESS') {
          this.$message.success(result.msg)
          this.$emit('close')
        } else {
          this.$message.error(result.msg)
        }
      }).catch((err) => {
        this.$message.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-container {
  margin-left: 110px;
  margin-right: 0;
  ::v-deep .el-radio {
    margin-right: 10px;
  }
}
.avatar-uploader {
  width: 130px;
  height: 160px;
  position: absolute;
  top: 100px;
  z-index: 2;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: px2vw(30);
  color: #8c939d;
}
.avatar {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.el-icon-delete {
  cursor: pointer;
}
.el-icon-delete:hover {
  color: rgb(92, 182, 255);
}
.preview-module {
  width: 1280px;
  margin: 0px auto;
  position: relative;
  padding: 60px 0;
}
.uploadbutton {
  margin: 0 10px;
}
::v-deep .el-cascader .el-input .el-input__inner {
  height: 40px !important;
}
.position-item {
  margin-right: 10px;
}
::v-deep .el-cascader .el-input .el-icon-arrow-down.is-reverse {
  height: 40px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>
