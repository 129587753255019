<!--
   User: Liu Yin
   Date: 2020/4/7
   Description:所获奖项
 -->
<template>
  <article>
    <div class="module-title-container">
      <div class="module-title-left" />
      <p class="module-title">所获奖项</p>
      <i v-if="handleEnable" class="el-icon-plus edit-btn" @click="add">添加</i>
    </div>
    <ul v-if="!showEdit">
      <li v-for="item in resume.prizes" :key="item.id+'certificate'" class="module-content-container">
        <section class="row-title">
          <span class="brief-introduction">奖项名称：</span>
          <span class="deep-grey-text">{{ item.prizeName }}</span>
          <div v-if="handleEnable" class="edit-btn">
            <i class="el-icon-edit" @click="edit(item)">编辑</i>
            <i class="el-icon-delete btn-remove" @click="remove(resume.prizes,urls.removePrize,item.id)">删除</i>
          </div>
        </section>
        <section class="row">
          <span class="brief-introduction">奖项级别</span>
          <div class="bias" />
          <span class="brief-introduction">{{ item.prizeGrade }}</span>
        </section>
      </li>
    </ul>
    <section v-else>
      <el-form
        ref="form"
        class="edit-container"
        label-width="130px"
        :model="formData"
        :rules="formRules"
        label-position="right"
      >
        <el-row :gutter="25">
          <el-col :xs="24" :sm="12" :lg="12">
            <el-form-item label="奖项名称：" prop="prizeName">
              <el-input
                v-model="formData.prizeName"
                placeholder="请输入奖项名称"
                maxlength="32"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="12">
            <el-form-item label="奖项级别：" prop="prizeGrade">
              <el-input
                v-model="formData.prizeGrade"
                placeholder="请输入奖项级别"
                maxlength="32"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <div class="edit-footer">
          <el-button @click="closeEdit">
            取消
          </el-button>
          <el-button type="primary" @click="validateForm()">
            确定
          </el-button>
        </div>
      </el-form>
    </section>
  </article>
</template>

<script>
import resumeEditMixin from '../../../../../../utils/mixins/resumeEditMixin'
export default {
  mixins: [resumeEditMixin],
  data() {
    return {
      formData: {
        prizeName: '',
        personId: this.personId,
        prizeGrade: ''
      },
      formRules: {
        prizeName: [
          { required: true, message: '请输入奖项名称', trigger: 'blur' }
        ],
        prizeGrade: [
          { required: true, message: '请选择奖项级别', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    edit(item) {
      for (const i in item) {
        this.formData[i] = item[i]
      }
      this.showEdit = true
    },
    submitForm() {
      this.$post(this.urls.savePrize, this.formData).then(result => {
        if (result.code === 'SUCCESS') {
          this.itemCommitSuccess(this.resume.prizes, this.formData)
        } else {
          this.$message.error('保存失败')
        }
      }).catch(() => {
        this.$message.error('提交失败')
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "../css/resume";

</style>
