/**
 * author Liu Yin
 * date 2020/4/3
 * Description:简历编辑混入
 */
export default {
  props: {
    resume: {
      type: Object,
      default: {}
    },
    personId: {
      type: [Number, Object],
      default() {
        return null
      }
    },
    // 是否可操作
    handleEnable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showEdit: false,
      isAdd: false
    }
  },
  mounted() {
  },
  methods: {
    closeEdit() {
      this.showEdit = false
    },
    remove(originalList, url, id) {
      this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.handleRemove(originalList, url, id)
      }).catch(e => {

      })
    },
    handleRemove(originalList, url, id) {
      this.$post(url, { id: id }).then(result => {
        if (result.code === 'SUCCESS') {
          this.$message.success('删除成功')
          for (let i = 0; i < originalList.length; i++) {
            const item = originalList[i]
            if (item.id === id) {
              originalList.splice(i, 1)
              break
            }
          }
        } else {
          this.$message.error('删除失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('删除失败')
      })
    },
    add() {
      this.isAdd = true
      for (const i in this.formData) {
        if (i === 'personId') {
          continue
        }
        this.formData[i] = ''
      }
      this.showEdit = true
    },
    // 单个条目添加或编辑提交成功
    itemCommitSuccess(originalList, updateItem) {
      const copyItem = JSON.parse(JSON.stringify(updateItem))
      this.$message.success('保存成功')
      if (!this.isAdd) {
        for (let i = 0; i < originalList.length; i++) {
          const item = originalList[i]
          if (item.id === copyItem.id) {
            originalList.splice(i, 1, copyItem)
            break
          }
        }
        this.closeEdit()
      } else {
        this.commitSuccess()
      }
    },
    // 整体对象编辑成功
    objCommitSuccess(obj, formData) {
      this.$message.success('保存成功')
      for (const i in formData) {
        obj[i] = formData[i]
      }
      this.closeEdit()
    },

    // 提交数据前校验表单
    validateForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm()
        } else {
          this.$message.error('请填写完整')
          return false
        }
      })
    },
    commitSuccess(personId) {
      this.closeEdit()
      this.$emit('saveSuccess')
    },
    getResume(personId) {
      this.toggleLoading(true)
      this.$get(this.urls.personInfo, {}, { id: personId }).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          const data = result.data
          const now = new Date().getTime()
          const ageMilli = now - new Date(data.birthdate).getTime()
          const workMilli = now - new Date(data.workdate).getTime()
          const age = Math.floor(ageMilli / (365 * 24 * 3600 * 1000)) + 1
          const workExperience = Math.floor(workMilli / (365 * 24 * 3600 * 1000)) + 1
          data.age = age
          data.workExperience = workExperience
          this.resume = data
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    }
  }
}
