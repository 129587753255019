<!--
   User: Liu Yin
   Date: 2020/7/30
   Description:企业详情
 -->
<template>
  <article>
    <!-- <el-breadcrumb v-for="(item,index) in sourceNumList" :key="index" separator-class="el-icon-arrow-right" class="breadcrumb">
      <el-breadcrumb-item v-if="item===1">
        <span class="curpoin" @click="close(sourceNum)">招聘推荐</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="item===3">
        <span class="curpoin" @click="close(sourceNum)">岗位列表</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="item===5">
        <span class="curpoin" @click="close(sourceNum)">企业列表</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="item===4">
        <span class="curpoin" @click="close(sourceNum)">公司详情</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="item===2">
        <span class="curpoin" @click="close(sourceNum)">岗位详情</span>
      </el-breadcrumb-item>
    </el-breadcrumb> -->
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item v-for="(item,index) in List" :key="index">
          <span class="curpoin" @click="close(item.id)">{{ item.name }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

  </article>
</template>

<script>
export default {
  name: 'BreadcrumbNav',
  props: {
    sourceNum: {
      type: Number,
      default: 1
    },
    sourceNumList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      List: []
    }
  },
  mounted() {
    this.sourceNumList.forEach(item => {
      if (item === 1) {
        this.List.push({
          id: 1,
          name: '招聘推荐'
        })
      } else if (item === 2) {
        this.List.push({
          id: 2,
          name: '岗位详情'
        })
      } else if (item === 3) {
        this.List.push({
          id: 3,
          name: '岗位列表'
        })
      } else if (item === 4) {
        this.List.push({
          id: 4,
          name: '公司详情'
        })
      } else if (item === 5) {
        this.List.push({
          id: 5,
          name: '企业列表'
        })
      } else if (item === 6) {
        this.List.push({
          id: 6,
          name: '简历列表'
        })
      } else if (item === 7) {
        this.List.push({
          id: 7,
          name: '简历详情'
        })
      }
    })
  },
  methods: {
    close(num) {
      this.$emit('close', num)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover {
    font-weight: 400;
    color: #00c2b3;
    cursor: text;
}
::v-deep .el-breadcrumb__inner {
    color: #00c2b3;
}
.curpoin{
    cursor: pointer;
  }
</style>
