<!--
   User: Liu Yin
   Date: 2020/4/1
   Description:
 -->
<template>
  <article class="item-container">
    <p class="item-container-title">{{ title }}：</p>
    <ul class="item-container-list">
      <li
        v-for="(item,index) in classifyList"
        :key="index"
        class="item-parent"
        @mouseenter="mouseEnterParent(index)"
        @mouseleave="mouseLeaveParent()"
      >
        <a
          class="parent-item-name font-14"
          :class="{'parent-node-focus':(parentSelect===item.dictValue||mouseFocus===index)}"
        >
          {{ item.dictValue }}
        </a>
        <ul
          v-if="mouseFocus===index&&item.children&&item.children.length>0"
          class="children"
        >
          <li
            v-for="(childrenItem,childIndex) in item.children"
            :key="childIndex"
            class="child-item font-14"
            :class="{'child-node-focus':childrenSelect===childrenItem.dictValue}"
            @click="handleSelect(item,childrenItem)"
          >
            {{ childrenItem.dictValue }}
          </li>
        </ul>
      </li>
    </ul>
  </article>
</template>

<script>
export default {
  props: {
    classifyList: {
      type: Array,
      default() {
        return []
      }
    },
    title: {
      type: String,
      default() {
        return ''
      }
    },
    value: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      // 一级分类
      parentSelect: null,
      // 鼠标焦点位置
      mouseFocus: -1,
      // 行业父类别
      childrenSelect: null
    }
  },
  beforeDestroy() {
    clearTimeout(this._timer)
  },
  methods: {
    mouseEnterParent(index) {
      // clearTimeout(this._timer)
      this.mouseFocus = index
    },
    mouseLeaveParent() {
      this.mouseFocus = -1
      // const self = this
      // self._timer = setTimeout(function() {
      //   self.mouseFocus = -1
      // }, 100)
    },
    mouseEnterChild() {
      clearTimeout(this._timer)
    },
    mouseLeaveChild() {
      this.mouseFocus = -1
    },
    handleSelect(parent, children) {
      this.childrenSelect = children.dictValue
      this.parentSelect = parent.dictValue
      this.$emit('input', children)
    },
    clearSelect() {
      this.parentSelect = null
      this.childrenSelect = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .item-container{
    display: flex;
    margin-top: 5px;
    .item-container-title{
      width: 110px;
      flex-shrink: 0;
      margin-top: 12px;
      font-weight: 500;
      font-size: 16px;
      color: #000;
    }
    .item-container-list{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      .item-parent{
        margin:10px 0;
        .parent-item-name{
          padding: 8px 12px;
          border-radius: 2px;
          cursor: pointer;
          box-sizing: border-box;
        }
        .parent-node-focus{
          background-color: rgb(233,233,233);
        }
      }
      .children{
        position: absolute;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // margin-top: 8px;
        background-color: rgb(233,233,233);
        width: 100%;
        padding: 5px 0;
        box-sizing: border-box;
        z-index: 1000;
        .child-item{
          margin: 5px 8px;
          font-family: arial,verdana,helvetica,'PingFang SC','HanHei SC',STHeitiSC-Light,Microsoft Yahei,sans-serif;
          padding:5px 10px;
          border-radius: 2px;
          cursor: pointer;
          box-sizing: border-box;
        }
        .child-node-focus,.child-item:hover{
          color: white;
        }
      }
    }
  }

</style>
