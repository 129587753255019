<!--
   User: Liu Yin
   Date: 2020/3/23
   Description:职位信息
 -->
<template>
  <article class="parent-container">
    <div style="background: white">
      <section class="condition-shadow" style="background: rgba(202,236,255,0.1)">
        <el-row :gutter="10" style="display: flex;align-items: center;justify-content: center;">
          <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="16">
            <article class="condition-area">
              <!-- <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
                <el-breadcrumb-item><span class="curpoin" @click="close(sourceNum)">招聘推荐</span></el-breadcrumb-item>
                <el-breadcrumb-item>岗位列表</el-breadcrumb-item>
              </el-breadcrumb> -->
              <Nav class="breadcrumb" :source-num-list="sourceNumList" @close="close"></Nav>
              <div class="keyWords-expectCity">
                <el-input
                  v-model="searchForm.jobname"
                  class="input-keyWords"
                  placeholder="请输入关键字搜索"
                  @keyup.enter.native="handleSearch"
                >
                  <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
                </el-input>
              </div>
              <classification
                ref="workAddress"
                :classify-list="areaList"
                title="工作地点"
                @input="handleSelectAddress($event,'workAddress')"
              ></classification>
              <classification
                ref="enterField"
                :classify-list="industryList"
                title="行业分类"
                @input="handleConditionSelect($event,'enterField')"
              ></classification>
              <dl class="search-item-container">
                <dt class="search-item-container-title">薪资待遇：</dt>
                <dd class="search-item-container-list">
                  <a
                    v-for="(item,index) in salaryList"
                    :key="index"
                    class="search-item-name font-14"
                    :class="{'search-item-name-focus':searchForm.salaryPackage===item.dictValue}"
                    @click="handleSelectSalary(item,'salaryPackage')"
                  >
                    {{ item.dictValue }}
                  </a>
                </dd>
              </dl>
              <float-label ref="floatLabel" class="float-label" :label-map="floatLabelMap" @handlerRemove="handleRemoveLabel"></float-label>
            </article>
          </el-col>
        </el-row>
      </section>
    </div>
    <el-row :gutter="10" style="display: flex;align-items: center;justify-content: center;">
      <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="16">
        <post-list
          v-if="showList"
          ref="list"
          class="post-list"
          :view-type="2"
          :dict-obj="dictObj"
          :search-obj="searchForm"
          @openDetail="openDetail"
        />
      </el-col>
    </el-row>
  </article>
</template>

<script>
import classification from './classification'
import FloatLabel from './FloatLabel'
import listContainerMixin from '../../../../../utils/mixins/listContainerMixin'
import pageMixin from '../../../../../utils/mixins/pageMixin'
import postList from './postList'
import Nav from './nav.vue'

export default {
  name: 'Post',
  components: {
    classification,
    FloatLabel,
    postList,
    Nav
  },
  mixins: [listContainerMixin, pageMixin],
  props: {
    sourceNum: {
      type: Number,
      default: 1
    },
    sourceNumList: {
      type: Array,
      default() {
        return [1, 3]
      }
    }
  },
  data() {
    return {
      searchForm: {
        // 岗位名称
        jobname: null,
        // 行业
        enterField: null,
        // 公司规模
        enterScale: null,
        // 融资情况
        enterFinance: null,
        // 薪资
        salaryPackage: null
      },
      // 行业列表
      industryList: [],
      areaList: [],
      // 融资阶段
      financialStageList: [],
      // 公司规模
      enterpriseScaleList: [],
      salaryList: [],
      // 浮动标签列表
      floatLabelMap: {},
      selectSalary: '',
      // 字典
      dictObj: {},
      showList: false
    }
  },
  mounted() {
    this.getDictObj()
  },
  methods: {
    close(num) {
      this.$emit('close', num)
    },
    openDetail(val, num) {
      this.$emit('openDetail', val, num)
    },
    clickIndustryItem(data) {
      this.searchForm.industryTitle = data.dictValue
      this.$refs.industryDrop.toggle()
      this.handleSearch()
    },
    changeSalary(code) {
      const match = this.salaryList.find(item => {
        if (item.value === code) {
          return true
        }
      })
      this.searchForm.salaryPackage = match.text
      this.handleSearch()
    },
    // 选中搜索条件,并将条件展示到浮动标签
    handlePitchOn(value, key) {
      this.$set(this.floatLabelMap, key, { value, key })
      this.handleSearch()
    },
    handleSelectAddress(item, key) {
      this.searchForm[key] = item.dictValue
      this.handlePitchOn(item.dictValue, key)
    },
    handleSelectSalary(item, key) {
      this.searchForm[key] = item.dictValue
      this.handlePitchOn(item.dictValue, key)
    },
    handleConditionSelect(item, key) {
      this.searchForm[key] = item.dictCode
      this.handlePitchOn(item.dictValue, key)
    },
    // 移除选择的标签
    handleRemoveLabel(item) {
      this.searchForm[item.key] = null
      const ref = this.$refs[item.key]
      if (ref) ref.clearSelect()
      this.handleSearch()
    },

    // 获取字典数据
    getDictObj() {
      this.$get(`${this.urls.dicValues}`, { types: 'enter_industry,area,enterprise_scale,education,financing_scale,salary_range' }).then(result => {
        if (result.code === 'SUCCESS') {
          const data = result.data
          this.dictObj = data
          this.industryList = this.converseData(data.enter_industry)
          this.areaList = this.converseData(data.area)
          this.financialStageList = data.financing_scale
          this.enterpriseScaleList = data.enterprise_scale
          this.salaryList = data.salary_range
          this.salaryList.forEach(item => {
            item.text = item.dictValue
            item.value = item.dictCode
          })
          this.showList = true
        } else {
          this.$message.error(result.msg)
        }
      }).catch((e) => {
        console.error(e)
        this.toggleLoading(false)
      })
    }

  },
  head: {
    title: '招聘岗位'
  }
}
</script>

<style lang="scss" scoped>

  .parent-container{
    background: rgb(246,246,248);
        line-height: 26px;
  }
dl.search-item-container {
    display: flex;
    align-items: center;
    margin-top: 8px;
}
dt.search-item-container-title {
    width: 110px;
    line-height: 20px;
    flex-shrink: 0;
    font-weight: 500;
    font-size: 16px;
    color: #000;
}
dd.search-item-container-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
a.search-item-name.font-14 {
    margin: 0 8px;
    padding: 8px 10px;
    border-radius: 2px;
    cursor: pointer;
}
.post-list{
  margin: 20px 0;
}
.input-keyWords.el-input.el-input-group.el-input-group--append {
    width: 700px;
    margin: 20px 0;
}
.breadcrumb{
  margin: 20px 0 0 0;
  padding: 0 0 10px 0;
    // border-bottom: 1px solid #bdbdbd;
    width: 100%;
}
.curpoin{
    cursor: pointer;
  }
.float-label{
  margin: 0 0 20px 0;
}
.el-row{
  padding-left:0;
  padding-right:0;
  margin-left:0 !important;
  margin-right:0 !important;
}
</style>
