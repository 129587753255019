<template>
  <article class="recommend">

    <el-row :gutter="10" style="display: flex; align-items: center; justify-content: center">
      <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="16">
        <Nav class="breadcrumb" :source-num-list="sourceNumList" @close="close"></Nav>
        <div>
          <section class="text-containerB">
            <ul v-if="tableData.length>0" class="container-ul">
              <li v-for="(item, index) in tableData" :key="index" class="container-li" @click="handleSee(item,7)">
                <div class="flexcenbet" style="width:100%">
                  <div class="user-info">
                    <div class="portrait">
                      <img :src="getHolderImage(item)">
                    </div>
                    <div class="details">
                      <p>
                        {{ formatName(item.personName) }}
                      </p>
                      <p>
                        {{
                          item.birthdate === null
                            ? "未填写"
                            : calculationDifference(item.birthdate) + "岁"
                        }}
                        <span class="line">|</span>
                        工作{{ item.workdate }}年
                        <span class="line">|</span>
                        {{ formatterData(dictionary.education, item.personEducation) }}
                        <span class="line">|</span>
                        {{ formatterData(dictionary.job_status, item.personState) }}
                      </p>
                      <p>
                        期望：
                        <span v-if="item.minSalary && item.maxSalary" style="color: #fb6e20">{{ formatterSalary(item.minSalary) }} -
                          {{ formatterSalary(item.maxSalary) }}</span>
                        <span v-else style="color: #fb6e20">{{
                          formatterSalary(item.minSalary)
                        }}</span>
                        <span class="line">|</span>
                        <span class="limit" :title="item.expectCity">
                          {{ item.expectCity }}
                        </span>
                        <span class="line">|</span>
                        <span class="limit" :title="item.personPosition">
                          {{ item.personPosition }}
                        </span>
                      </p>
                      <p v-if="item.skillLabels" class="tags">
                        <span v-for="(itemskill, index1) in item.skillLabels.slice(0,4)" :key="index1" :title="itemskill.skillName">{{ itemskill.skillName }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="experience-info">
                    <ul>
                      <div v-if="item.workExperiences">
                        <li v-for="(itemWork, indexWork) in item.workExperiences.slice(0,3)" :key="indexWork">
                          <div class="time">
                            {{ formatDate(itemWork.startTime) }} -
                            {{ formatDate(itemWork.endTime) }}
                          </div>
                          <div class="company" :title="itemWork.workCompany">
                            {{ itemWork.workCompany }}
                          </div>
                          <div class="position" :title="itemWork.workPosition">
                            {{ itemWork.workPosition }}
                          </div>
                        </li>
                      </div>
                      <!-- 因为上方for循环的key已经有重复的key,所以下方需要从4开始进行循环 -->
                      <div v-if="item.educations">
                        <li v-for="(itemEdu, indexEdu) in item.educations.slice(0, 1)" :key="indexEdu+4">
                          <div class="time">
                            {{ formatDate(itemEdu.startTime) }} -
                            {{ formatDate(itemEdu.endTime) }}
                          </div>
                          <div class="company" :title="itemEdu.schoolName">
                            {{ itemEdu.schoolName }}
                          </div>
                          <div class="position" :title="itemEdu.schoolMajor">
                            {{ itemEdu.schoolMajor }}
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            <el-empty v-else description="暂无简历"></el-empty>
            <el-pagination class="pageAction" :current-page="currentPage" :page-size="size" :background="false" layout="prev, pager, next, jumper" :total="total" style="text-align: center;" @current-change="handleCurrentChanges" />
          </section>
        </div>
      </el-col>
    </el-row>
  </article>
</template>

<script>

import dictMixin from '../../wisdom/mixins/dictMixin'
import boyHolder from '../../../../../assets/images/boy.jpg'
import girlHolder from '../../../../../assets/images/girl.jpg'
import pageMixin from '../../../../../utils/mixins/pageMixin'
import listMixin from '../../../../../utils/mixins/listMixin'
import Nav from './nav.vue'

export default {
  components: { Nav },
  mixins: [dictMixin, pageMixin, listMixin],
  data() {
    return {
      searchForm: {},
      postRecords: [],
      companyRecords: [],
      dictionary: {},
      financialStageList: [],
      industryList: [],
      postId: null,
      postFlag: 1,
      sourceNum: 1,
      enterId: null,
      sourceNumList: [1, 6],
      tableData: [],
      personId: null
    }
  },
  mounted() {
    this.getdictionary()
    this.requestData()
  },
  methods: {
    formatName(str) {
      return str.substring(0, 1) + new Array(str.length).join('*')
    },
    close(num) {
      this.$emit('close', num)
    },
    getHolderImage(val) {
      const sex = val.personSex
      if (sex === '0') {
        return girlHolder
      } else {
        return boyHolder
      }
    },
    // 请求列表数据
    requestData() {
      this.toggleLoading(true)
      this.$get(this.urls.enterSettledList, this.searchForm, [this.currentPage, this.size]).then((result) => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          const data = result.data
          this.tableData = data.records
          this.calculateWorking(this.tableData)
          this.total = result.data.total
        } else {
          this.$message.error(result.msg)
        }
      })
        .catch(() => {
          this.toggleLoading(true)
        })
    },
    formatDate(time) {
      if (time !== null) {
        var date = new Date(time)
        var YY = date.getFullYear() + '/'
        var MM =
          date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1
        return YY + MM
      } else {
        return '至今'
      }
    },
    calculateWorking(data) {
      data.forEach(element => {
        const now = new Date().getTime()
        if (element.workdate) {
          const workMilli = now - new Date(element.workdate).getTime()
          const workExperience = Math.floor(workMilli / (365 * 24 * 3600 * 1000)) + 1
          element.workdate = workExperience
        } else if (element.educations.length > 0) {
          if (new Date(element.educations[0].endTime).getTime()) {
            const workMilli = now - new Date(element.educations[0].endTime).getTime()
            const workExperience = Math.floor(workMilli / (365 * 24 * 3600 * 1000)) + 1
            element.workdate = workExperience
          }
        } else {
          element.workdate = '0'
        }
      })
    },
    getIndustryName(val) {
      if (!val || val.length === 0) return ''
      const firstIndex = val.split(',')[0]
      return this.formattingField(
        this.industryList,
        firstIndex,
        'dictCode',
        'dictValue'
      )
    },
    // 获取字典数据
    getdictionary() {
      this.$get(`${this.urls.dicValues}`, {
        types: 'education,enter_industry,enterprise_scale,job_status'
      })
        .then((result) => {
          if (result.code === 'SUCCESS') {
            this.dictionary = result.data
            this.industryList = this.dictionary.enter_industry
            this.financialStageList = this.dictionary.enterprise_scale
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch(() => {
          this.toggleLoading(false)
        })
    },
    handleSee(item, num) {
      // this.personId = row.personId
      // this.postFlag = 4
      // this.sourceNumList = [1, 6, 7]
      this.$emit('openeditresume', item, 7)
    },
    calculationDifference(dateTime) {
      if (dateTime !== undefined && dateTime != null) {
        const birthdays = new Date(dateTime.replace(/-/g, '/'))
        const d = new Date()
        const age =
          d.getFullYear() -
          birthdays.getFullYear() -
          (d.getMonth() < birthdays.getMonth() ||
            (d.getMonth() === birthdays.getMonth() &&
              d.getDate() < birthdays.getDate())
            ? 1
            : 0)
        return age
      } else {
        return 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-ul .container-li:hover {
  transition: all 0.2s ease;
  transform: translateY(-3%);
  // box-shadow: 0 12px 20px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 12px 9px 0 rgb(0 0 0 / 20%);
  z-index: 1;
}
//   .container-ul .container-li:nth-child(odd) {
// background-color: #f7f7f7;
// }
// .container-ul .container-li:nth-child(even) {
// background-color: #fff;
// }
.text-containerB {
  .container-ul {
    .container-li {
      cursor: pointer;
      width: 100%;
      // margin-bottom: 10px;
      min-height: 154px;
      // background: #ffffff;
      // box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
  }
}
.user-info {
  // width: 33%;
  height: 100%;
  display: flex;
  // align-items: center;
  width: 50%;
  // min-width: 470px ;
  .portrait {
    height: 100%;
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin: 24px 20px 0 24px;
    }
  }
  .details {
    // width: 100%;
    height: 100%;
    // padding-top: 24px;
    color: #57606a;
    min-width: 346px;
    P {
      margin-top: 14px;
      .line {
        margin: 0 8px;
        color: #dcdfe6;
      }
      .tags-status {
        border-radius: 2px;
        margin: 2px 4px;
        border: 1px solid #0dd7bb;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #0dd7bb;
      }
    }
    .tags {
      span {
        padding: 0 8px;
        margin-right: 4px;
        display: inline-block;
        height: 24px;
        background: #f1f4f9;
        font-size: 12px;
        border-radius: 12px;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 70px;
      }
    }
  }
}
.experience-info {
  // flex: 1;
  // margin-left: 160px;
  width: 50%;
  ul {
    position: relative;
    li {
      display: flex;
      color: #57606a;
      margin-bottom: 14px;
      padding: 1em 0 0 0;
      font-size: 14px;
      justify-content: space-between;
      align-items: center;
      .time {
        min-width: 120px;
        margin-right: 24px;
      }
      .company {
        width: 135px;
        margin-right: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .position {
        flex: 1;
        width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    li:before {
      position: absolute;
      margin-top: 3px;
      left: -28px;
      z-index: 2;
      content: " ";
    }
    li:after {
      position: absolute;
      top: 30px;
      z-index: 1;
      left: -23px;
      bottom: 7px;
      content: " ";
      z-index: 1;
      border-right: 2px solid #e2edff;
    }
    li:not(:first-child) {
      padding-top: 8px;
    }
    li:last-child {
      li:after {
        display: none;
      }
    }
    // 设置图标
    li:first-child::before {
      background-image: url("../../../../../assets/images/resume-start.png");
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      height: 13px;
      width: 13px;
      border-radius: 50%;
    }
    li:last-child::before {
      background-image: url("../../../../../assets/images/resume-end.png");
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      height: 13px;
      width: 13px;
      border-radius: 50%;
    }
    li.is-done {
      li:after {
        border-color: #0091fa;
      }
      li:before {
        background-color: #1874ff;
        box-shadow: #1874ff 0 0 10px;
      }
    }
  }
}
//   .container-ul .container-li:nth-child(odd) {
// background-color: #f7f7f7;
// }
// .container-ul .container-li:nth-child(even) {
// background-color: #fff;
// }
.container-li {
  background-color: #fff;
  box-shadow: 0 0px 5px 0 rgb(0 0 0 / 10%);
  margin-top: 10px;
}
.flexcenbet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
// .recommend{
//       margin: 0 0 30px 0;
// }
.breadcrumb {
  // margin: 20px 0 20px 0;
  padding: 20px 0;
  // border-bottom: 1px solid #bdbdbd;
  width: 100%;
}
.limit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 230px;
}
.el-row {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
::v-deep .el-pagination {
  button {
    background: initial;
    .el-icon {
      font-size: 17px;
    }
  }
  .number {
    background: initial;
    font-size: 16px;
  }
}
</style>
